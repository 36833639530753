import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoutes } from "./ProtectedRoutes";
import AcademicYear from "../view/academic/academic-year/AcademicYear";
import AcademicYearForm from "../view/academic/academic-year/AcademicYearForm";
import Classes from "../view/academic/classes/Classes";
import ClassesForm from "../view/academic/classes/ClassesForm";
import Sections from "../view/academic/sections/Sections";
import TimeSlots from "../view/academic/time-slots/TimeSlots";
import TimeSlotsForm from "../view/academic/time-slots/TimeSlotsForm";
import Courses from "../view/academic/courses/Courses";
import ClassRoutines from "../view/academic/class-routines/ClassRoutines";
import CoursesForm from "../view/academic/courses/CoursesForm";
import ClassRoutineForm from "../view/academic/class-routines/ClassRoutineForm";
import Calendar from "../view/academic/academic-calendar/Calendar";
import CalendarForm from "../view/academic/academic-calendar/CalendarForm";
import Assignments from "../view/academic/assignments/Assignments";
import AssignmentForm from "../view/academic/assignments/AssignmentForm";
import AssignmentSubmission from "../view/academic/assignment-submissions/AssignmentSubmission";
import AssignmentSubmissionForm from "../view/academic/assignment-submissions/AssignmentSubmissionForm";
import Homeworks from "../view/academic/homeworks/Homeworks";
import HomeworkForm from "../view/academic/homeworks/HomeworkForm";
import HomeworkSubmission from "../view/academic/homework-submission/HomeworkSubmission";
import HomeworkSubmissionForm from "../view/academic/homework-submission/HomeworkSubmissionForm";
import AssignmentFeedback from "../view/academic/assignment-feedback/AssignmentFeedback";
import AssignmentFeedbackForm from "../view/academic/assignment-feedback/AssignmentFeedbackForm";
import HomeworkFeedback from "../view/academic/homework-feedback/HomeworkFeedback";
import HomeworkFeedbackForm from "../view/academic/homework-feedback/HomeworkFeedbackForm";

export default function AcademicRoutes() {
  return (
    <Routes>
      <Route
        path="/academic-years"
        element={<ProtectedRoutes Component={AcademicYear} />}
      />
      <Route
        path="/academic-years/add-new"
        element={<ProtectedRoutes Component={AcademicYearForm} />}
      />
      <Route
        path="/academic-years/:id"
        element={<ProtectedRoutes Component={AcademicYearForm} />}
      />
      {/* classes */}
      <Route
        path="/classes"
        element={<ProtectedRoutes Component={Classes} />}
      />
      <Route
        path="/classes/add-class"
        element={<ProtectedRoutes Component={ClassesForm} />}
      />
      <Route
        path="/classes/:id"
        element={<ProtectedRoutes Component={ClassesForm} />}
      />
      {/* sections */}
      <Route
        path="/sections"
        element={<ProtectedRoutes Component={Sections} />}
      />
      {/* time slots */}
      <Route
        path="/time-slots"
        element={<ProtectedRoutes Component={TimeSlots} />}
      />
      <Route
        path="/time-slots/add-time-slot"
        element={<ProtectedRoutes Component={TimeSlotsForm} />}
      />
      <Route
        path="/time-slots/:id"
        element={<ProtectedRoutes Component={TimeSlotsForm} />}
      />
      {/* Courses */}
      <Route
        path="/courses"
        element={<ProtectedRoutes Component={Courses} />}
      />
      <Route
        path="/courses/add-course"
        element={<ProtectedRoutes Component={CoursesForm} />}
      />
      <Route
        path="/courses/:id"
        element={<ProtectedRoutes Component={CoursesForm} />}
      />
      {/* class routines */}
      <Route
        path="/class-routines"
        element={<ProtectedRoutes Component={ClassRoutines} />}
      />
      <Route
        path="/class-routines/add-class-routine"
        element={<ProtectedRoutes Component={ClassRoutineForm} />}
      />
      <Route
        path="/class-routines/:id"
        element={<ProtectedRoutes Component={ClassRoutineForm} />}
      />
      {/* calendar */}
      <Route
        path="/calendars"
        element={<ProtectedRoutes Component={Calendar} />}
      />
      <Route
        path="/calendars/add-calendar"
        element={<ProtectedRoutes Component={CalendarForm} />}
      />
      <Route
        path="/calendars/:id"
        element={<ProtectedRoutes Component={CalendarForm} />}
      />
      {/* assignments */}
      <Route
        path="/assignments"
        element={<ProtectedRoutes Component={Assignments} />}
      />
      <Route
        path="/assignments/add-assignment"
        element={<ProtectedRoutes Component={AssignmentForm} />}
      />
      <Route
        path="/assignments/:id"
        element={<ProtectedRoutes Component={AssignmentForm} />}
      />

      {/* assignment submission*/}
      <Route
        path="/assignment-submissions"
        element={<ProtectedRoutes Component={AssignmentSubmission} />}
      />
      <Route
        path="/assignment-submissions/add-assignment-submission"
        element={<ProtectedRoutes Component={AssignmentSubmissionForm} />}
      />
      <Route
        path="/assignment-submissions/:id"
        element={<ProtectedRoutes Component={AssignmentSubmissionForm} />}
      />
      <Route
        path="/assignment-feedback"
        element={<ProtectedRoutes Component={AssignmentFeedback} />}
      />
      <Route
        path="/assignment-feedback/add-feedback"
        element={<ProtectedRoutes Component={AssignmentFeedbackForm} />}
      />
      <Route
        path="/assignment-feedback/:id"
        element={<ProtectedRoutes Component={AssignmentFeedbackForm} />}
      />
      <Route
        path="/homework-feedback"
        element={<ProtectedRoutes Component={HomeworkFeedback} />}
      />
      <Route
        path="/homework-feedback/add-feedback"
        element={<ProtectedRoutes Component={HomeworkFeedbackForm} />}
      />
      <Route
        path="/homework-feedback/:id"
        element={<ProtectedRoutes Component={HomeworkFeedbackForm} />}
      />

      {/* Homework */}
      <Route
        path="/homeworks"
        element={<ProtectedRoutes Component={Homeworks} />}
      />
      <Route
        path="/homeworks/add-homework"
        element={<ProtectedRoutes Component={HomeworkForm} />}
      />
      <Route
        path="/homeworks/:id"
        element={<ProtectedRoutes Component={HomeworkForm} />}
      />
      {/* Homework submission */}
      <Route
        path="/homework-submissions"
        element={<ProtectedRoutes Component={HomeworkSubmission} />}
      />
      <Route
        path="/homework-submissions/add-homework-submission"
        element={<ProtectedRoutes Component={HomeworkSubmissionForm} />}
      />
      <Route
        path="/homework-submissions/:id"
        element={<ProtectedRoutes Component={HomeworkSubmissionForm} />}
      />
    </Routes>
  );
}
