import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getData,
  postData,
  putData,
  patchData,
} from "../../../axios/axiosInstance";
import { message } from "antd";

export const createSliceFactory = ({ name, url, fetchParams = () => ({}) }) => {
  // Fetch all data with params
  const fetchAll = createAsyncThunk(
    `${name}/fetchAll`,
    async (params, { rejectWithValue }) => {
      console.log("params slice factory", params);

      try {
        const response = await getData(url, params);
        return {
          data: response.data.results,
          total: response.data.count,
        };
      } catch (error) {
        console.log("fetchError slice factory", error.response);

        return rejectWithValue(
          error.response?.data || { detail: "Something went wrong" }
        );
      }
    }
  );

  // Fetch a single item by ID
  const fetchSingle = createAsyncThunk(
    `${name}/fetchSingle`,
    async (id, { rejectWithValue }) => {
      try {
        const response = await getData(`${url}${id}/`);
        return response.data;
      } catch (error) {
        return rejectWithValue(
          error.response?.data || { detail: "Something went wrong" }
        );
      }
    }
  );

  // Post new data
  const postDataThunk = createAsyncThunk(
    `${name}/postData`,
    async ({ payload, onSuccess }, { rejectWithValue }) => {
      console.log("payload from redux", payload);

      try {
        const response = await postData(url, payload);
        if (response.status === 200 || response.status === 201) {
          if (onSuccess) {
            onSuccess();
          }
        }
        return response.data;
      } catch (error) {
        console.log("post error", error.response);

        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.name?.[0] ||
            error.response.data.non_field_errors?.[0] ||
            error.response.statusText ||
            "Failed to create item.";
          message.error(errorMessage);
        }
        return rejectWithValue(
          error.response?.data || { detail: "Something went wrong" }
        );
      }
    }
  );

  // Update existing data (PUT)
  const updateDataThunk = createAsyncThunk(
    `${name}/updateData`,
    async ({ id, payload }, { rejectWithValue }) => {
      try {
        const response = await putData(`${url}${id}/`, payload);
        message.success("Item updated successfully!");
        return response.data;
      } catch (error) {
        if (error.response?.status === 400 || error.response?.status === 405) {
          const errorMessage =
            error.response.data.message ||
            error.response.data.name?.[0] ||
            error.response.data.detail ||
            error.response.statusText ||
            "Failed to update item.";
          message.error(errorMessage);
        }
        return rejectWithValue(
          error.response?.data || { detail: "Something went wrong" }
        );
      }
    }
  );

  // Patch existing data (PATCH)
  const patchDataThunk = createAsyncThunk(
    `${name}/patchData`,
    async ({ id, payload }, { rejectWithValue }) => {
      try {
        const response = await patchData(`${url}${id}/`, payload);
        message.success("Item updated successfully!");
        return response.data;
      } catch (error) {
        if (error.response?.status === 400) {
          const errorMessage =
            error.response.data.message ||
            error.response.data.name?.[0] ||
            error.response.statusText ||
            "Failed to patch item.";
          message.error(errorMessage);
        }
        return rejectWithValue(
          error.response?.data || { detail: "Something went wrong" }
        );
      }
    }
  );

  // Create the slice
  const slice = createSlice({
    name,
    initialState: {
      data: [], // For list of items
      singleData: {}, // For single item details
      isLoading: false, // Loading state for all actions
      error: null, // Error handling
      totalItems: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        // Fetch All
        .addCase(fetchAll.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchAll.fulfilled, (state, action) => {
          state.isLoading = false;
          state.data = action.payload.data;
          state.totalItems = action.payload.total;
          state.error = null;
        })
        .addCase(fetchAll.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        })

        // Fetch Single
        .addCase(fetchSingle.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchSingle.fulfilled, (state, action) => {
          state.isLoading = false;
          state.singleData = action.payload;
          state.error = null;
        })
        .addCase(fetchSingle.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        })

        // Post Data
        .addCase(postDataThunk.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(postDataThunk.fulfilled, (state, action) => {
          state.isLoading = false;
          state.data = [...state.data, action.payload];
          state.error = null;
        })
        .addCase(postDataThunk.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        })

        // Update Data
        .addCase(updateDataThunk.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateDataThunk.fulfilled, (state, action) => {
          state.isLoading = false;
          // Find and update the existing item in the list
          state.data = state.data.map((item) =>
            item.id === action.payload.id ? action.payload : item
          );
          state.error = null;
        })
        .addCase(updateDataThunk.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        })

        // Patch Data
        .addCase(patchDataThunk.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(patchDataThunk.fulfilled, (state, action) => {
          state.isLoading = false;
          // Find and update the partially updated item in the list
          state.data = state.data.map((item) =>
            item.id === action.payload.id ? action.payload : item
          );
          state.error = null;
        })
        .addCase(patchDataThunk.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        });
    },
  });

  return {
    reducer: slice.reducer,
    fetchAll,
    fetchSingle,
    postData: postDataThunk,
    updateData: updateDataThunk,
    patchData: patchDataThunk, // Expose the patch action
  };
};
