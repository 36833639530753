import { Button, Image, message, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { fetchAll as fetchAcademicYears } from "../../../libs/redux/features/academic/academicYearNewSlice";
import { postData } from "../../../libs/axios/axiosInstance";

const { Option } = Select;

const PromoteModal = ({ open, onClose, data }) => {
  const dispatch = useDispatch();
  const classesData = useSelector((state) => state.classesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);
  const academicYearsData = useSelector((state) => state.academicYearsNew.data);

  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchClasses());
    dispatch(fetchAcademicYears());
  }, [dispatch]);

  const [modalData, setModalData] = useState({
    class_id: null,
    section_id: null,
    academic_year_id: null,
  });

  const isSection =
    modalData.class_id &&
    classesData.some(
      (item) => item.id === modalData.class_id && item.has_sections
    );

  useEffect(() => {
    if (modalData.class_id) {
      dispatch(fetchSections({ class_level: modalData.class_id }));
    }
  }, [dispatch, modalData.class_id]);

  const handlePromote = async () => {
    setLoading(true);
    const payload = modalData;
    try {
      const response = await postData(
        `student/admin/students/${data.id}/promote/`,
        payload
      );
      if (response.status === 201) {
        message.success("successfully promoted the student");
      }
    } catch (error) {
      console.log("error promoting student", error);
      setError(error.response.data);
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      title={<p className="text-dark fw-6 fs-4">Promote Student</p>}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handlePromote}
          disabled={loading}
          loading={loading}
        >
          Promote
        </Button>,
      ]}
    >
      <div className="my-3 border rounded-5 p-3 flex items-center">
        <Image
          className="me-3"
          style={{ height: 150, maxWidth: 180, objectFit: "cover" }}
          src={data.user.image.image}
          alt={data.user.first_name}
        />
        <div>
          <div>
            <span className="fs-4">Name: </span>{" "}
            <span className="ms-3 fs-4 fw-6">
              {data.user.first_name} {data.user.last_name}
            </span>
          </div>
          <div className="">
            <span className="fs-4">Current Class: </span>{" "}
            <span className="ms-3 fw-6 fs-4">{data.current_class}</span>
          </div>
          <div className="">
            <span className="fs-4">Current Section: </span>{" "}
            <span className="ms-3 fs-4 fw-6">{data.current_section}</span>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="mb-3">
          <div className="mb-2 flex aic">
            <p className="ps-2 fw-6">* Promote to</p>
            {error && error.class_id ? (
              <p className="text-sm text-danger ms-3">({error.class_id})</p>
            ) : (
              ""
            )}
          </div>
          <Select
            className="w-100"
            size="large"
            value={modalData.class_id || null}
            onChange={(value) =>
              setModalData((prev) => ({
                ...prev,
                class_id: value,
              }))
            }
            status={error && error.class_id ? "error" : ""}
            showSearch
            placeholder="Select Class"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear
          >
            {classesData &&
              classesData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        </div>
        {isSection && (
          <div className="mb-3">
            <div className="mb-2 flex aic">
              <p className="ps-2 fw-6">Section</p>
              {error && error.section_id ? (
                <p className="text-sm text-danger ms-3">({error.section_id})</p>
              ) : (
                ""
              )}
            </div>
            <Select
              className="w-100"
              size="large"
              value={modalData.section_id}
              onChange={(value) =>
                setModalData((prev) => ({
                  ...prev,
                  section_id: value,
                }))
              }
              status={error && error.section_id ? "error" : ""}
              showSearch
              placeholder="Select Section"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
            >
              {sectionsData &&
                sectionsData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </div>
        )}
        <div className="mb-3">
          <div className="mb-2 flex aic">
            <p className="ps-2 fw-6">* Academic Year</p>
            {error && error.academic_year_id ? (
              <p className="text-sm text-danger ms-3">
                ({error.academic_year_id})
              </p>
            ) : (
              ""
            )}
          </div>
          <Select
            className="w-100"
            size="large"
            value={modalData.academic_year_id || null}
            onChange={(value) =>
              setModalData((prev) => ({
                ...prev,
                academic_year_id: value,
              }))
            }
            status={error && error.academic_year_id ? "error" : ""}
            showSearch
            placeholder="Select Class"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            allowClear
          >
            {academicYearsData && academicYearsData.length > 0
              ? academicYearsData.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item.year}
                  </Option>
                ))
              : ""}
          </Select>
        </div>
      </div>
    </Modal>
  );
};

export default PromoteModal;
