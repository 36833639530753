import { Breadcrumb, Button, Checkbox, Pagination, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchCourses } from "../../../libs/redux/features/academic/coursesNewSlice";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";
import { fetchAll as fetchTimeSlots } from "../../../libs/redux/features/academic/timeslotsNewSlice";
import { fetchAll as fetchClassRoutines } from "../../../libs/redux/features/academic/routinesNewSlice";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  weekDayData,
} from "../../../components/utility";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import { GrMultiple } from "react-icons/gr";
import RoutineUploadModal from "./RoutineUploadModal";
import { debounceFetchWithSearch } from "../../../libs/axios/fetchUtility";

const { Option } = Select;

const ClassRoutines = () => {
  usePageTitle("Class Routines");

  const dispatch = useDispatch();

  const data = useSelector((state) => state.routinesNew.data);
  const isLoading = useSelector((state) => state.routinesNew.isLoading);
  const totalItems = useSelector((state) => state.routinesNew.totalItems);

  const { data: classesData, isLoading: classLoading } = useSelector(
    (state) => state.classesNew
  );
  const { data: coursesData, isLoading: courseLoading } = useSelector(
    (state) => state.coursesNew
  );
  const { data: teachersData, isLoading: teacherLoading } = useSelector(
    (state) => state.teachersNew
  );
  const timeslotData = useSelector((state) => state.timeslotsNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }

    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
    if (timeslotData.length === 0) {
      dispatch(fetchTimeSlots());
    }
  }, [dispatch, classesData, coursesData, teachersData, timeslotData]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    class_info: null,
    course: null,
    teacher: null,
    time_slot: null,
    weekday: "all",
  });

  useEffect(() => {
    if (filters.class_info) {
      dispatch(fetchCourses({ class_level: filters.class_info }));
    }
  }, [filters.class_info, dispatch]);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.class_info !== null) {
      params.class_info = filters.class_info;
    }
    if (filters.course !== null) {
      params.course = filters.course;
    }
    if (filters.teacher !== null) {
      params.teacher = filters.teacher;
    }
    if (filters.time_slot !== null) {
      params.time_slot = filters.time_slot;
    }
    if (filters.weekday !== "all") {
      params.weekday = filters.weekday;
    }
    dispatch(fetchClassRoutines(params));
  }, [dispatch, filters]);

  useEffect(() => {
    if (selectAll) {
      setSelectedItems(data);
    } else {
      setSelectedItems([]);
    }
  }, [selectAll, data]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCheckboxChange = (record) => {
    const isSelected = selectedItems.some((item) => item.id === record.id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item.id !== record.id));
    } else {
      setSelectedItems([...selectedItems, record]);
    }
  };

  const columns = [
    {
      dataIndex: "select",
      width: 50,
      render: (_, record) => (
        <Checkbox
          checked={selectedItems.some((item) => item.id === record.id)}
          onChange={() => handleCheckboxChange(record)}
        />
      ),
      title: (
        <Checkbox
          checked={selectAll}
          indeterminate={
            selectedItems.length > 0 && selectedItems.length < data.length
          }
          onChange={(e) => setSelectAll(e.target.checked)}
        />
      ),
    },
    {
      title: "#",
      dataIndex: "id",
      width: 45,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Week Day",
      dataIndex: "weekday",
      render: (weekdayId) => {
        const weekday = weekDayData.find((day) => day.id === weekdayId);
        return weekday ? weekday.title : "Unknown";
      },
    },
    {
      title: "Class",
      dataIndex: "class_info_name",
    },
    {
      title: "Course",
      dataIndex: "course_name",
    },
    {
      title: "Teacher",
      dataIndex: "teacher_name",
    },
    {
      title: "Time Slot",
      dataIndex: "time_slot_duration",
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/class-routines/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.course_name} class of ${record.time_slot_duration}`,
                dispatch,
                `academic/admin/class-routines/${record.id}/`,
                fetchClassRoutines
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => {
    const weekday =
      weekDayData.find((day) => day.id === item.weekday)?.title || "N/A";

    return {
      Serial: index + 1,
      "Week Day": weekday,
      // "Academic Year": academicYearDisplay,
      Class: item.class_info_name,
      Courses: item.course_name,
      Teacher: item.teacher_name,
      "Time Slots": item.time_slot_duration,
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Week Day",
      // "Academic Year",
      "Class",
      "Courses",
      "Teacher",
      "Time Slots",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Class Routine Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Class Routine Data");
  };

  const [bulkUploadModal, setBulkUploadModal] = useState(false);

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Class Routines</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Academic" },
              { title: "Class Routines" },
            ]}
          />
        </div>
        <div className="flex aic g-3">
          <Button
            icon={<GrMultiple />}
            size="large"
            onClick={() => setBulkUploadModal(true)}
          >
            {selectedItems.length > 0 ? "Bulk Update" : "Bulk Upload"}
          </Button>
          <Link to="/academic/class-routines/add-class-routine">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Select
              showSearch
              loading={classLoading}
              className="w-200"
              placeholder="Select Class"
              value={filters.class_info || null}
              onChange={(value) => handleFilterChange("class_info", value)}
              onSearch={(searchValue) =>
                debounceFetchWithSearch(searchValue, dispatch, fetchClasses)
              }
              filterOption={false}
              allowClear
            >
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              className="w-200"
              loading={courseLoading}
              placeholder="Select Course"
              value={filters.course || null}
              onChange={(value) => handleFilterChange("course", value)}
              onSearch={(searchValue) =>
                debounceFetchWithSearch(searchValue, dispatch, fetchCourses)
              }
              filterOption={false}
              allowClear
            >
              {coursesData &&
                coursesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              className="w-200"
              loading={teacherLoading}
              placeholder="Select Teacher"
              value={filters.teacher || null}
              onChange={(value) => handleFilterChange("teacher", value)}
              onSearch={(searchValue) =>
                debounceFetchWithSearch(searchValue, dispatch, fetchTeachers)
              }
              filterOption={false}
              allowClear
            >
              {teachersData &&
                teachersData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.user.first_name} {item.user.last_name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              className="w-200"
              placeholder="Select Timeslot"
              optionFilterProp="children"
              value={filters.time_slot || null}
              onChange={(value) => handleFilterChange("time_slot", value)}
              onClear={() => handleFilterChange("time_slot", null)}
              allowClear
            >
              {timeslotData &&
                timeslotData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.start_time} - {item.end_time}
                  </Option>
                ))}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("weekday", value)}
            >
              <Option value="all">All Days</Option>
              {weekDayData.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {bulkUploadModal && (
        <RoutineUploadModal
          open={bulkUploadModal}
          onCancel={() => setBulkUploadModal(false)}
          dataToModify={selectedItems}
          onSuccess={() => dispatch(fetchClassRoutines())}
        />
      )}
    </div>
  );
};

export default ClassRoutines;
