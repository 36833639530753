import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { axiosInstance } from "../../../axios/axiosBase";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ SignInData, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("auth/login/", SignInData);
      console.log("login response", response);
      if (response.status === 200) {
        const token = response.data.token;
        localStorage.setItem("token", `Token ${token}`);
        localStorage.setItem("owner", response.data.id);
        localStorage.setItem("role", response.data.role);
        await navigate("/");
        message.success(`Welcome back ${response.data.first_name}`);
        return response.data;
      }
    } catch (error) {
      console.log("login error", error.response);

      if (error.response.status === 400) {
        const errorData = error.response.data || {};
        const errorMessage =
          errorData.mobile_or_email?.[0] || errorData.non_field_errors?.[0];

        return rejectWithValue({
          errorMessage,
          errors: {
            mobile_or_email: errorData.mobile_or_email?.[0] || "",
            password: errorData.non_field_errors?.[0] || "",
          },
        });
      }
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    loginResponse: {},
    errors: {},
    errorMessage: null,
  },

  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.errors = {};
        state.errorMessage = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.loginResponse = action.payload;
        state.errors = {};
        state.errorMessage = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || {};
        state.errorMessage = action.payload.errorMessage;
      });
  },
});

export const { logout, setNavigator } = authSlice.actions;

export default authSlice.reducer;
