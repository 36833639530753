import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Signin from "../view/auth/Signin";
import MainLayout from "../view/MainLayout";
import { ProtectedRoutes } from "./ProtectedRoutes";
import Profile from "../view/profile/Profile";
import StudentsRoutes from "./studentsRoutes";
import AcademicRoutes from "./academicRoutes";
import WebsiteRoutes from "./websiteRoutes";
// import GalleryPage from "../view/Test";
import Homepage from "../view/home-page/Homepage";
import TeacherRoutes from "./teacherRoutes";
import OnlineClasses from "../view/learning/OnlineClasses";
import OnlineClassesForm from "../view/learning/OnlineClassesForm";
import StudyMaterials from "../view/learning/StudyMaterials";
import StudyMaterialForm from "../view/learning/StudyMaterialForm";
import ExamRoutes from "./examRoutes";
import AccountingRoutes from "./accountingRoutes";
import Users from "../view/auth/Users";
import UsersForm from "../view/auth/UsersForm";
import PayrollRoutes from "./payrollRoutes";
import HomeTeacher from "../view/teacher-panel/home/HomeTeacher";

export default function AppRoutes() {
  const [role, setRole] = useState(localStorage.getItem("role"));

  useEffect(() => {
    const handleStorageChange = () => {
      setRole(localStorage.getItem("role"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  console.log(role);

  return (
    <Routes>
      <Route path="/sign-in" element={<Signin />} />
      {role === "0" ? (
        <Route path="/" element={<ProtectedRoutes Component={MainLayout} />}>
          <Route index element={<ProtectedRoutes Component={Homepage} />} />

          <Route
            path="profile"
            element={<ProtectedRoutes Component={Profile} />}
          />
          {/* students */}
          <Route path="student-data/*" element={<StudentsRoutes />} />
          {/* academic */}
          <Route path="academic/*" element={<AcademicRoutes />} />
          {/* teacher */}
          <Route path="teachers-info/*" element={<TeacherRoutes />} />
          {/* website */}
          <Route path="website/*" element={<WebsiteRoutes />} />
          {/* learning */}
          <Route
            path="/learning/online-classes"
            element={<ProtectedRoutes Component={OnlineClasses} />}
          />
          <Route
            path="/learning/online-classes/add-class"
            element={<ProtectedRoutes Component={OnlineClassesForm} />}
          />
          <Route
            path="/learning/online-classes/:id"
            element={<ProtectedRoutes Component={OnlineClassesForm} />}
          />
          {/* learning */}
          <Route
            path="/learning/study-materials"
            element={<ProtectedRoutes Component={StudyMaterials} />}
          />
          <Route
            path="/learning/study-materials/add-study-material"
            element={<ProtectedRoutes Component={StudyMaterialForm} />}
          />
          <Route
            path="/learning/study-materials/:id"
            element={<ProtectedRoutes Component={StudyMaterialForm} />}
          />
          {/* exams */}
          <Route path="exam-data/*" element={<ExamRoutes />} />
          {/* accounting */}
          <Route path="accounting/*" element={<AccountingRoutes />} />
          {/* users */}
          <Route path="/users" element={<Users />} />
          <Route path="/users/add-user" element={<UsersForm />} />
          <Route path="/users/:id" element={<UsersForm />} />
          {/* payroll */}
          <Route path="payroll-info/*" element={<PayrollRoutes />} />
        </Route>
      ) : (
        <Route path="/" element={<ProtectedRoutes Component={MainLayout} />}>
          <Route index element={<ProtectedRoutes Component={HomeTeacher} />} />
        </Route>
      )}
    </Routes>
  );
}
