import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadBtn from "../../../components/BreadBtn";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll } from "../../../libs/redux/features/student-info/studentsSlice";
import dayjs from "dayjs";
import PageCard from "../../../components/PageCard";
import {
  Button,
  Checkbox,
  DatePicker,
  Image,
  message,
  Select,
  Table,
} from "antd";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";
import { postData } from "../../../libs/axios/axiosInstance";
import StudentAttendanceModal from "./StudentAttendanceModal";
import { postData as postInRedux } from "../../../libs/redux/features/student-info/studentAttendanceSlice";

const { Option } = Select;

const StudentAttendanceForm = () => {
  const breadcrumbItems = [
    { title: "Teachers Info" },
    {
      title: (
        <Link to="/student-data/student-attendances">Student Attendances</Link>
      ),
    },
    { title: "Add Attendance" },
  ];

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.studentAttendance.isLoading);
  const studentsData = useSelector((state) => state.students.data);

  const classesData = useSelector((state) => state.classesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);
  const teachersData = useSelector((state) => state.teachersNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
  }, [dispatch, classesData.length, sectionsData.length, teachersData.length]);

  const [attendance, setAttendance] = useState({
    date: null,
    class_attended: null,
    section_attended: null,
    teacher: null,
    attendances: [],
  });

  useEffect(() => {
    if (attendance.class_attended) {
      dispatch(fetchSections({ class_level: attendance.class_attended }));
    } else {
      dispatch(fetchSections());
    }
  }, [attendance.class_attended, dispatch]);

  const [attendanceModal, setAttendanceModal] = useState(false);
  const [modalStudent, setModalStudent] = useState({});
  const [attendanceLoading, setAttendanceLoading] = useState(false);

  useEffect(() => {
    let params = {};
    if (attendance.class_attended) {
      params.school_class = attendance.class_attended;
    }
    if (attendance.section_attended !== null) {
      params.section = attendance.section_attended;
    }

    if (Object.keys(params).length > 0) {
      dispatch(fetchAll(params));
    } else {
      dispatch(fetchAll());
    }
  }, [dispatch, attendance.class_attended, attendance.section_attended]);

  // This effect will run when studentsData changes
  useEffect(() => {
    if (studentsData) {
      const initialAttendance = {
        date: dayjs(),
        class_attended: attendance.class_attended || null,
        section_attended: attendance.section_attended || null,
        teacher: attendance.teacher || null,
        attendances: studentsData.map((student) => ({
          student: student.id,
          status: "Present",
        })),
      };
      setAttendance(initialAttendance);
    }
  }, [
    studentsData,
    attendance.class_attended,
    attendance.section_attended,
    attendance.teacher,
  ]);

  const handleCheckboxChange = (studentId, checked) => {
    setAttendance((prevAttendance) => ({
      ...prevAttendance,
      attendances: prevAttendance.attendances.map((item) =>
        item.student === studentId
          ? { ...item, status: checked ? "Present" : "Absent" }
          : item
      ),
    }));
  };

  const columns = [
    {
      title: "Select",
      dataIndex: "select",
      key: "select",
      render: (_, student) => {
        const attendanceItem = attendance.attendances.find(
          (att) => att.student === student.id
        );
        return (
          <Checkbox
            checked={attendanceItem?.status === "Present"}
            onChange={(e) => handleCheckboxChange(student.id, e.target.checked)}
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "user",
      render: (user) => (
        <div className="flex aic">
          <Image
            src={user.image.image}
            alt={user.image.alt_text}
            style={{
              width: 50,
              height: 50,
              borderRadius: 10,
              marginRight: 10,
            }}
          />
          <span>{`${user.first_name} ${user.last_name}`}</span>
        </div>
      ),
    },
    {
      title: "ID",
      dataIndex: "student_id",
    },
    {
      title: "Class",
      dataIndex: "current_class",
    },
    {
      title: "Section",
      dataIndex: "current_section",
    },
    {
      title: "Single Attendance",
      dataIndex: "single_attendance",
      key: "single_attendance",
      render: (_, student) => (
        <Button
          size="small"
          type="primary"
          onClick={() => handleAttendanceModalData(student)}
        >
          Give Attendance
        </Button>
      ),
    },
  ];

  const handleAttendanceModalData = (teacher) => {
    setModalStudent(teacher);
    setAttendanceModal(true);
  };

  const cancelAttendanceModal = () => {
    setAttendanceModal(false);
  };

  const handleSubmit = async () => {
    if (attendance.class_attended && attendance.teacher) {
      const formattedAttendance = {
        ...attendance,
        date: dayjs(attendance.date).format("YYYY-MM-DD"),
      };
      console.log("student attendance payload", formattedAttendance);
      setAttendanceLoading(true);
      try {
        const response = await postData(
          "student/admin/attendance/bulk-create/",
          formattedAttendance
        );
        if (response.status === 201) {
          message.success("Student attendance submitted successfully!");
        }
        console.log("bulk student attendance", response);
      } catch (error) {
        console.log("error for bulk student attendance", error.response);
        const errorMessage = error.response.data.section_attended
          ? "Section can not be empty!"
          : error.response.data.class_attended
          ? "Class can not be empty!"
          : error.response.data.teacher
          ? "Teacher can not be empty!"
          : "Error posting student attendance!";
        message.error(errorMessage);
      } finally {
        setAttendanceLoading(false);
      }
    } else {
      message.error("Class / Teacher is not selected!");
    }
  };

  const handleModalSubmit = (payload) => {
    const data = payload;
    console.log("payload", data);
    dispatch(
      postInRedux({
        payload: data,
      })
    );
  };

  return (
    <div>
      <BreadBtn headerTitle="Student Attendance" items={breadcrumbItems} />
      <PageCard
        title="Attendance Form"
        content={
          <>
            <div className="flex aic g-3 mb-3">
              <DatePicker
                className="w-200"
                placeholder="Attendance date"
                value={attendance.date}
                onChange={(date) =>
                  setAttendance((prev) => ({
                    ...prev,
                    date: date,
                  }))
                }
              />
              <Select
                className="w-200"
                placeholder="Select Class"
                value={attendance.class_attended || null}
                onChange={(value) =>
                  setAttendance((prev) => ({
                    ...prev,
                    class_attended: value,
                  }))
                }
              >
                {classesData && classesData.length > 0
                  ? classesData.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))
                  : ""}
              </Select>
              <Select
                className="w-200"
                value={attendance.section_attended || null}
                placeholder="Select Section"
                onChange={(value) =>
                  setAttendance((prev) => ({
                    ...prev,
                    section_attended: value,
                  }))
                }
              >
                {sectionsData && sectionsData.length > 0
                  ? sectionsData.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))
                  : ""}
              </Select>
              <Select
                showSearch
                optionFilterProp="children"
                className="w-200"
                value={attendance.teacher || null}
                placeholder="Select Teacher"
                onChange={(value) =>
                  setAttendance((prev) => ({
                    ...prev,
                    teacher: value,
                  }))
                }
              >
                {teachersData && teachersData.length > 0
                  ? teachersData.map((item) => (
                      <Option value={item.id} key={item.id}>
                        {item.user.first_name} {item.user.last_name}
                      </Option>
                    ))
                  : ""}
              </Select>
            </div>
            <Table
              dataSource={studentsData}
              columns={columns}
              rowKey={(record) => record.id}
              pagination={false}
              scroll={{ x: 900 }}
            />
            <Button
              type="primary"
              size="large"
              className="w-100 mt-5"
              onClick={handleSubmit}
              loading={attendanceLoading}
            >
              Submit Attendance
            </Button>
          </>
        }
      />

      {/* student attendance modal */}
      {attendanceModal && (
        <StudentAttendanceModal
          visible={attendanceModal}
          onCancel={cancelAttendanceModal}
          onSubmit={handleModalSubmit}
          teachers={teachersData}
          classes={classesData}
          sections={sectionsData}
          student={modalStudent}
          initialAttendance={{
            date: dayjs(),
            status: "Present",
            class_attended: classesData?.[0]?.id,
            section_attended: sectionsData?.[0]?.id,
            teacher: teachersData?.[0]?.id,
            student: modalStudent.id,
          }}
          loading={isLoading}
        />
      )}
    </div>
  );
};

export default StudentAttendanceForm;
