import {
  Button,
  Checkbox,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchSingle as fetchSingleCourse,
  postData as postCourse,
  updateData as putCourse,
} from "../../../libs/redux/features/academic/coursesNewSlice";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import TextArea from "antd/es/input/TextArea";
import usePageTitle from "../../../components/PageTitle";
import BreadBtn from "../../../components/BreadBtn";
import { postData } from "../../../libs/axios/axiosInstance";
import { debounceFetchWithSearch } from "../../../libs/axios/fetchUtility";

const { Option } = Select;

const CoursesForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const singleData = useSelector((state) => state.coursesNew.singleData);
  const isLoading = useSelector((state) => state.coursesNew.isLoading);
  const error = useSelector((state) => state.coursesNew.error);

  const { data: classesData, isLoading: classLoading } = useSelector(
    (state) => state.classesNew
  );

  usePageTitle(id && singleData.name ? singleData.name : "Add Course");

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
  }, [dispatch, classesData.length]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleCourse(id));
    }
  }, [id, dispatch]);

  const [course, setCourse] = useState({
    name: "",
    description: "",
    total_marks: 0,
    is_active: true,
    class_level: null,
  });

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setCourse({
        name: singleData.name,
        description: singleData.description,
        total_marks: singleData.total_marks,
        is_active: singleData.is_active,
        class_level: singleData.class_level,
      });
    }
  }, [id, singleData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(course);
    const payload = course;
    if (id) {
      dispatch(putCourse({ id, payload }));
    } else {
      dispatch(
        postCourse({
          payload,
          onSuccess: () => {
            message.success("New course created successfully!");
            setCourse({
              class_level_name: "",
              name: "",
              description: "",
              total_marks: 0,
              is_active: true,
              class_level: null,
            });
          },
        })
      );
    }
  };

  const breadcrumbitems = [
    {
      title: "Academic",
    },
    {
      title: <Link to="/academic/courses">Courses</Link>,
    },
    {
      title: id && singleData.name ? singleData.name : "Add Course",
    },
  ];

  const [bulkModal, setBulkModal] = useState(false);
  const [bulkModalData, setBulkModalData] = useState([
    {
      name: "",
      description: "",
      total_marks: "",
      class_level: null,
      is_active: true,
    },
  ]);
  const [bulkModalLoading, setBulkModalLoading] = useState(false);
  const [bulkModalError, setBulkModalError] = useState([]);

  const bulkModalCancel = () => {
    setBulkModal(false);
    setBulkModalData([
      {
        name: "",
        description: "",
        total_marks: "",
        class_level: null,
        is_active: true,
      },
    ]);
  };

  const handleModalOk = async () => {
    setBulkModalLoading(true);
    const payload = bulkModalData;

    try {
      const response = await postData(
        "academic/admin/courses/bulk-create/",
        payload
      );
      if (response.status === 201) {
        message.success("Courses created successfully");
        bulkModalCancel();
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setBulkModalError(error.response.data); // Set errors in state
      }
      console.log("error posting bulk course", error.response);
    } finally {
      setBulkModalLoading(false);
    }
  };

  const handleAddCourse = () => {
    setBulkModalData((prev) => [
      ...prev,
      {
        name: "",
        description: "",
        total_marks: "",
        class_level: null,
        is_active: true,
      },
    ]);
  };

  const handleRemoveCourse = (index) => {
    setBulkModalData((prev) => prev.filter((_, i) => i !== index));
  };

  // Function to handle input change for each course
  const handleCourseChange = (index, key, value) => {
    setBulkModalData((prev) =>
      prev.map((course, i) =>
        i === index ? { ...course, [key]: value } : course
      )
    );
  };

  return (
    <div>
      <BreadBtn
        headerTitle={id && singleData.name ? singleData.name : "Add Course"}
        items={breadcrumbitems}
        btnClick={() => setBulkModal(true)}
        addButton={true}
        addTitle="Bulk Create"
      />

      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Course Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  {/* <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Class Level Name</p>
                        {error && error.class_level_name ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.class_level_name})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Class Level name"
                        required
                        value={course.class_level_name}
                        onChange={(e) =>
                          setCourse((prev) => ({
                            ...prev,
                            class_level_name: e.target.value,
                          }))
                        }
                        status={error && error.class_level_name ? "error" : ""}
                      />
                    </div>
                  </Col> */}
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Course Name</p>
                        {error && error.name ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.name})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Name"
                        required
                        value={course.name}
                        onChange={(e) =>
                          setCourse((prev) => ({
                            ...prev,
                            name: e.target.value,
                          }))
                        }
                        status={error && error.name ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <p className="mb-2 ps-2 fw-6">* Description</p>
                      <TextArea
                        className="w-100"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="Description"
                        required
                        value={course.description}
                        onChange={(e) =>
                          setCourse((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Total Marks</p>
                        {error && error.total_marks ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.total_marks})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        type="number"
                        size="large"
                        placeholder="Total Marks"
                        required
                        value={course.total_marks}
                        onChange={(e) =>
                          setCourse((prev) => ({
                            ...prev,
                            total_marks: e.target.value,
                          }))
                        }
                        status={error && error.total_marks ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Class</p>
                        {error && error.class_level ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.class_level})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        loading={classLoading}
                        status={error && error.class_level ? "error" : ""}
                        placeholder="Select Class"
                        className="w-100"
                        size="large"
                        value={course.class_level}
                        onChange={(value) =>
                          setCourse((prev) => ({
                            ...prev,
                            class_level: value,
                          }))
                        }
                        onSearch={(searchValue) =>
                          debounceFetchWithSearch(
                            searchValue,
                            dispatch,
                            fetchClasses
                          )
                        }
                        filterOption={false}
                        allowClear
                      >
                        {classesData &&
                          classesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="my-5">
                      <Checkbox
                        checked={course.is_active}
                        onChange={(e) =>
                          setCourse((prev) => ({
                            ...prev,
                            is_active: e.target.checked,
                          }))
                        }
                      >
                        Is Active ?
                      </Checkbox>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {id ? "Update" : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
      {/* bulk modal */}
      {bulkModal && (
        <Modal
          title={<h2 className="fs-3 mb-3">Courses Bulk Upload</h2>}
          open={bulkModal}
          onCancel={bulkModalCancel}
          width={1000}
          footer={[
            <Button key="cancel" onClick={bulkModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={bulkModalLoading}
              loading={bulkModalLoading}
            >
              Submit
            </Button>,
          ]}
        >
          <Row className="responsive-row mb-4">
            {bulkModalData.map((course, index) => (
              <Col key={index} className="parent-col mb-3" span={24} sm={12}>
                <div className="border p-3 rounded-5">
                  <div className="flex-justify-between px-3 mb-3">
                    <h4 className="fs-4">Course {index + 1}</h4>
                    {bulkModalData.length > 1 ? (
                      <p
                        className="text-danger fw-6 c-pointer"
                        onClick={() => handleRemoveCourse(index)}
                      >
                        Remove
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  {bulkModalError[index] &&
                    bulkModalError[index].non_field_errors && (
                      <div className="text-danger mb-2 px-3 text-sm">
                        {bulkModalError[index].non_field_errors.join(", ")}
                      </div>
                    )}
                  <Row>
                    <Col span={24}>
                      <div className="mb-3">
                        <div className="mb-2 flex aic">
                          <p className="ps-2 fw-6">* Course Name</p>
                          {bulkModalError && bulkModalError.name ? (
                            <p className="text-sm text-danger ms-3">
                              ({bulkModalError.name})
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <Input
                          className="w-100"
                          placeholder="Name"
                          required
                          value={course.name}
                          onChange={(e) =>
                            handleCourseChange(index, "name", e.target.value)
                          }
                          status={
                            bulkModalError && bulkModalError.name ? "error" : ""
                          }
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="mb-3">
                        <p className="mb-2 ps-2 fw-6">* Description</p>
                        <Input
                          className="w-100"
                          placeholder="Description"
                          required
                          value={course.description}
                          onChange={(e) =>
                            handleCourseChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="mb-3">
                        <div className="mb-2 flex aic">
                          <p className="ps-2 fw-6">* Total Marks</p>
                          {bulkModalError && bulkModalError.total_marks ? (
                            <p className="text-sm text-danger ms-3">
                              ({bulkModalError.total_marks})
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <Input
                          className="w-100"
                          type="number"
                          placeholder="Total Marks"
                          required
                          value={course.total_marks}
                          onChange={(e) =>
                            handleCourseChange(
                              index,
                              "total_marks",
                              e.target.value
                            )
                          }
                          status={
                            bulkModalError && bulkModalError.total_marks
                              ? "error"
                              : ""
                          }
                        />
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="mb-3">
                        <div className="mb-2 flex aic">
                          <p className="ps-2 fw-6">* Class</p>
                          {bulkModalError && bulkModalError.class_level ? (
                            <p className="text-sm text-danger ms-3">
                              ({bulkModalError.class_level})
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <Select
                          status={
                            bulkModalError && bulkModalError.class_level
                              ? "error"
                              : ""
                          }
                          className="w-100"
                          value={course.class_level}
                          onChange={(value) =>
                            handleCourseChange(index, "class_level", value)
                          }
                        >
                          {classesData &&
                            classesData.map((item) => (
                              <Option value={item.id} key={item.id}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </Col>
                    <Col span={24} sm={12}>
                      <div className="my-3">
                        <Checkbox
                          checked={course.is_active}
                          onChange={(e) =>
                            handleCourseChange(
                              index,
                              "is_active",
                              e.target.checked
                            )
                          }
                        >
                          Is Active ?
                        </Checkbox>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            ))}
          </Row>
          <div className="w-100 flex justify-center mt-5">
            <Button className="p-3" shape="circle" onClick={handleAddCourse}>
              +
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default CoursesForm;
