import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { fetchAll as fetchCourses } from "../../../libs/redux/features/academic/coursesNewSlice";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";
import {
  fetchSingle as fetchSingleAssignment,
  postData as postAssignment,
  updateData as putAssignment,
} from "../../../libs/redux/features/academic/assignmentsNewSlice";
import usePageTitle from "../../../components/PageTitle";
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Row,
  Select,
  Spin,
  Tag,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { getDocIcon } from "../../../components/utility";
import DocumentPopup from "../../../components/DocumentPopup";
import { HomeOutlined } from "@ant-design/icons";

const { Option } = Select;

const AssignmentForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const singleData = useSelector((state) => state.assignmentsNew.singleData);
  const isLoading = useSelector((state) => state.assignmentsNew.isLoading);
  const error = useSelector((state) => state.assignmentsNew.error);

  const classesData = useSelector((state) => state.classesNew.data);
  const coursesData = useSelector((state) => state.coursesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);
  const teachersData = useSelector((state) => state.teachersNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }

    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
  }, [dispatch, classesData.length, teachersData.length]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleAssignment(id));
    }
  }, [dispatch, id]);

  usePageTitle(id && singleData.title ? singleData.title : "Add Assignment");

  const [assignment, setAssignment] = useState({
    title: "",
    description: "",
    assigned_date: "",
    due_date: "",
    course: null,
    class_level: null,
    section: null,
    teacher: null,
    attachments: [],
  });

  useEffect(() => {
    if (assignment.class_level !== null) {
      dispatch(fetchCourses({ class_level: assignment.class_level }));
      dispatch(fetchSections({ class_level: assignment.class_level }));
    }
    // else {
    // dispatch(fetchCourses());
    // dispatch(fetchSections());
    // }
  }, [assignment.class_level, dispatch]);

  const isSection =
    assignment.class_level &&
    classesData.some(
      (item) => item.id === assignment.class_level && item.has_sections
    );

  useEffect(() => {
    if (id && !isLoading && Object.keys(singleData).length > 0) {
      setAssignment({
        title: singleData.title,
        description: singleData.description,
        assigned_date: singleData.assigned_date,
        due_date: singleData.due_date,
        course: singleData.course,
        class_level: singleData.class_level,
        section: singleData.section,
        teacher: singleData.teacher,
        attachments: singleData.attachments_data,
      });
    }
  }, [id, singleData, isLoading]);

  const [docModal, setDocModal] = useState(false);

  const handleDocs = (data) => {
    setAssignment((prev) => ({
      ...prev,
      attachments: [...prev.attachments, ...data],
    }));
    setDocModal(false);
  };

  const handleClose = (removedItem) => {
    setAssignment((prev) => ({
      ...prev,
      attachments: prev.attachments.filter(
        (item) => item.id !== removedItem.id
      ),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...assignment,
      attachments:
        assignment.attachments.length > 0
          ? assignment.attachments.map((item) => item.id)
          : [],
    };
    if (!isSection) {
      delete payload.section;
    }
    console.log("assingment payload", payload);
    if (id) {
      dispatch(putAssignment({ id, payload }));
    } else {
      dispatch(
        postAssignment({
          payload,
          onSuccess: () => {
            message.success("New assignment created successfully!");
            setAssignment({
              title: "",
              description: "",
              assigned_date: "",
              due_date: "",
              course: null,
              class_level: null,
              section: null,
              teacher: null,
              attachments: [],
            });
          },
        })
      );
    }
  };
  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id && singleData.title ? singleData.title : "Add Assignment"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              {
                title: "Academic",
              },
              {
                title: <Link to="/academic/assignments">Assignments</Link>,
              },
              {
                title:
                  id && singleData.title ? singleData.title : "Add Assignment",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Assignment Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Title</p>
                        {error && error.title ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.title})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Title"
                        required
                        value={assignment.title}
                        onChange={(e) =>
                          setAssignment((prev) => ({
                            ...prev,
                            title: e.target.value,
                          }))
                        }
                        status={error && error.title ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Description</p>
                        {error && error.description ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.description})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <TextArea
                        className="w-100"
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        placeholder="Description"
                        required
                        value={assignment.description}
                        onChange={(e) =>
                          setAssignment((prev) => ({
                            ...prev,
                            description: e.target.value,
                          }))
                        }
                        status={error && error.title ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Assigned Date</p>
                        {error && error.assigned_date ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.assigned_date})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <DatePicker
                        className="w-100"
                        size="large"
                        placeholder="Assigned Date"
                        required
                        value={
                          assignment.assigned_date
                            ? dayjs(assignment.assigned_date)
                            : null
                        }
                        onChange={(value) =>
                          setAssignment((prev) => ({
                            ...prev,
                            assigned_date: value
                              ? dayjs(value).format("YYYY-MM-DD")
                              : "",
                          }))
                        }
                        status={error && error.assigned_date ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Due Date</p>
                        {error && error.due_date ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.due_date})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <DatePicker
                        className="w-100"
                        size="large"
                        placeholder="Due Date"
                        required
                        value={
                          assignment.due_date
                            ? dayjs(assignment.due_date)
                            : null
                        }
                        onChange={(value) =>
                          setAssignment((prev) => ({
                            ...prev,
                            due_date: value
                              ? dayjs(value).format("YYYY-MM-DD")
                              : "",
                          }))
                        }
                        status={error && error.due_date ? "error" : ""}
                      />
                    </div>
                  </Col>

                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Class</p>
                        {error && error.class_level ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.class_level})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        className="w-100"
                        size="large"
                        value={assignment.class_level || null}
                        onChange={(value) =>
                          setAssignment((prev) => ({
                            ...prev,
                            class_level: value,
                          }))
                        }
                        status={error && error.class_level ? "error" : ""}
                        showSearch
                        placeholder="Select Class"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        allowClear
                      >
                        {classesData &&
                          classesData.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>

                  {isSection && (
                    <Col span={24}>
                      <div className="mb-3">
                        <div className="mb-2 flex aic">
                          <p className="ps-2 fw-6">Section</p>
                          {error && error.section ? (
                            <p className="text-sm text-danger ms-3">
                              ({error.section})
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <Select
                          className="w-100"
                          size="large"
                          value={assignment.section}
                          onChange={(value) =>
                            setAssignment((prev) => ({
                              ...prev,
                              section: value,
                            }))
                          }
                          status={error && error.section ? "error" : ""}
                          showSearch
                          placeholder="Select Section"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          allowClear
                        >
                          {sectionsData &&
                            sectionsData.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    </Col>
                  )}
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Course</p>
                        {error && error.course ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.course})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        className="w-100"
                        size="large"
                        value={assignment.course}
                        onChange={(value) =>
                          setAssignment((prev) => ({
                            ...prev,
                            course: value,
                          }))
                        }
                        status={error && error.course ? "error" : ""}
                        showSearch
                        placeholder="Select Course"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        allowClear
                      >
                        {coursesData &&
                          coursesData.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Teacher</p>
                        {error && error.teacher ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.teacher})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        className="w-100"
                        size="large"
                        value={assignment.teacher}
                        onChange={(value) =>
                          setAssignment((prev) => ({
                            ...prev,
                            teacher: value,
                          }))
                        }
                        status={error && error.teacher ? "error" : ""}
                        showSearch
                        placeholder="Select Teacher"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        allowClear
                      >
                        {teachersData &&
                          teachersData.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.user.first_name} {item.user.last_name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24} className="mb-3">
                    <p className="mb-2">* Attachments</p>
                    <div className="flex aic">
                      <Button
                        onClick={() => setDocModal(true)}
                        className={`flex justify-center aic rounded-10 ${
                          error && error.attachments
                            ? "border-dashed-double"
                            : "border-dashed"
                        }`}
                        style={{ height: 100, width: 100 }}
                        danger={error && error.attachments ? true : false}
                      >
                        + Upload
                      </Button>
                      <div className="ms-3">
                        {assignment.attachments &&
                        assignment.attachments.length > 0 ? (
                          assignment.attachments.map((item) => {
                            return (
                              <Tag
                                key={item.id}
                                closable
                                onClose={() => handleClose(item)}
                                icon={getDocIcon(item.doc_type)}
                                className="m-1 p-2 flex aic"
                                color="success"
                              >
                                {item.title}
                              </Tag>
                            );
                          })
                        ) : error && error.attachments ? (
                          <p className="text-danger">{error.attachments}</p>
                        ) : (
                          "No file selected"
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {id ? "Update" : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
      <DocumentPopup
        open={docModal}
        onCancel={() => setDocModal(false)}
        onUploadSuccess={handleDocs}
        docDescription={assignment.description}
      />
    </div>
  );
};

export default AssignmentForm;
