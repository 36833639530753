import { useDispatch, useSelector } from "react-redux";
import usePageTitle from "../../../components/PageTitle";
import { useEffect, useState } from "react";
import { fetchAll } from "../../../libs/redux/features/student-info/studentsSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import { Link } from "react-router-dom";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import BreadBtn from "../../../components/BreadBtn";
import DataConversion from "../../../components/DataConversion";
import { Pagination, Select, Table, Tag } from "antd";
import { fetchAll as fetchHomeworks } from "../../../libs/redux/features/academic/homeworksNewSlice";
import { fetchAll as fetchHomeworkSubmissions } from "../../../libs/redux/features/academic/homeworkSubmissionsSlice";
import { debounceFetchWithSearch } from "../../../libs/axios/fetchUtility";

const { Option } = Select;

const HomeworkSubmission = () => {
  usePageTitle("Homework Submissions");

  const breadcrumbitems = [
    {
      title: "Academic",
    },
    { title: "Homework Submissions" },
  ];

  const dispatch = useDispatch();

  const data = useSelector((state) => state.homeworkSubmissions.data);
  const isLoading = useSelector((state) => state.homeworkSubmissions.isLoading);
  const totalItems = useSelector(
    (state) => state.homeworkSubmissions.totalItems
  );

  const { data: homeworksData, isLoading: homeworkLoading } = useSelector(
    (state) => state.homeworksNew
  );
  const { data: studentsData, isLoading: studentLoading } = useSelector(
    (state) => state.students
  );

  useEffect(() => {
    dispatch(fetchHomeworks());
    dispatch(fetchAll());
  }, [dispatch]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    status: "all",
    homework: null,
    student: null,
  });

  useEffect(() => {
    const params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.status !== "all") {
      params.status = filters.status;
    }
    if (filters.homework) {
      params.homework = filters.homework;
    }
    if (filters.student) {
      params.student = filters.student;
    }

    dispatch(fetchHomeworkSubmissions(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const student = studentsData.find((student) => student.id === item.student);

    return {
      Serial: index + 1,
      Student: `${student.user.first_name} ${student.user.last_name}` || "N/A",
      Homework: item.homework || "N/A",
      "Is Graded": item.is_graded ? "Yes" : "No" || "N/A",
      Grade: item.grade || "N/A",
      Feedback: item.feedback || "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Student",
      "Homework",
      "Is Graded",
      "Grade",
      "Feedback",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "homework submission Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "homework submission Data");
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Student",
      dataIndex: "student_name",
    },
    {
      title: "Homework",
      dataIndex: "homework_title",
    },
    {
      title: "Submission Date",
      dataIndex: "submitted_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) =>
        status === "Submitted" ? (
          <Tag color="green">Submitted</Tag>
        ) : (
          <Tag color="Red">Late</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link
            to={`/academic/homework-submissions/${record.id}`}
            className="me-4"
          >
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.student}`,
                dispatch,
                `academic/admin/homework-submissions/${record.id}/`,
                fetchHomeworkSubmissions,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <BreadBtn
        headerTitle="Homework Submissions"
        items={breadcrumbitems}
        addPath="/academic/homework-submissions/add-homework-submission"
      />
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Select
              showSearch
              loading={studentLoading}
              className="w-200"
              placeholder="Select Student"
              value={filters.student || null}
              onChange={(value) => handleFilterChange("student", value || "")}
              onSearch={(searchValue) =>
                debounceFetchWithSearch(searchValue, dispatch, fetchAll)
              }
              filterOption={false}
              allowClear
              onClear={() => handleFilterChange("student", null)}
            >
              {studentsData && studentsData.length > 0
                ? studentsData.map((student) => (
                    <Option value={student.id} key={student.id}>
                      {student.user.first_name} {student.user.last_name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              loading={homeworkLoading}
              showSearch
              className="w-200"
              placeholder="Select Homework"
              value={filters.homework || null}
              onChange={(value) => handleFilterChange("homework", value)}
              onSearch={(searchValue) =>
                debounceFetchWithSearch(searchValue, dispatch, fetchHomeworks)
              }
              filterOption={false}
              allowClear
              onClear={() => handleFilterChange("homework", null)}
            >
              {homeworksData && homeworksData.length > 0
                ? homeworksData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.title}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("status", value)}
            >
              <Option value="all">All Status</Option>
              <Option value={"Submitted"}>Submitted</Option>
              <Option value={"Late"}>Late</Option>
            </Select>
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default HomeworkSubmission;
