import { Checkbox, Input, message, Modal, Spin, Upload } from "antd";
import React, { useState } from "react";
import { createFormDataImage } from "../../../components/utility";
import { uploadImage } from "../../../libs/axios/axiosInstance";

const AddImage = ({ open, onCancel, onUploadSuccess }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imagePayload, setImagePayload] = useState({
    title: "",
    alt_text: "",
    caption: "",
    is_gallery_image: true,
  });

  const handleImages = ({ fileList: newImageList }) => {
    setImages(newImageList);
  };

  const handleRemove = (image) => {
    setImages((prevImages) =>
      prevImages.filter((item) => item.uid !== image.uid)
    );
  };

  const handleImageUpload = async (e) => {
    e.preventDefault();

    let newImages = [];
    if (images && images.length > 0) {
      if (imagePayload.title === "") {
        message.warning("Image title is required");
      } else if (imagePayload.alt_text === "") {
        message.warning("Image alt text is required");
      } else {
        setLoading(true);
        try {
          const uploadPromises = images.map((image) => {
            const formData = createFormDataImage(
              image.originFileObj,
              imagePayload.title,
              imagePayload.caption,
              imagePayload.alt_text,
              imagePayload.is_gallery_image
            );
            return uploadImage(formData);
          });

          const uploadResponses = await Promise.all(uploadPromises);
          newImages = uploadResponses.map((response) => response.data);
          console.log(newImages);

          onUploadSuccess();
          setImagePayload({
            title: "",
            alt_text: "",
            caption: "",
            is_gallery_image: true,
          });
          setImages([]);
          setLoading(false);
        } catch (error) {
          console.log("error uploading images from popup", error);
          setLoading(false);
          return;
        }
      }
    } else {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      width={1000}
      onOk={handleImageUpload}
      className="custom-modal"
    >
      <Spin spinning={loading}>
        <div className="image-upload rounded-5 h-100">
          <h2 className="mb-2 fs-2 text-dark">+ Add Image</h2>
          <div className="mb-3 flex-justify-between wrap image-upload-input-wrap">
            <div className="w-25-p">
              <Input
                placeholder="Image Title"
                value={imagePayload.title}
                onChange={(e) =>
                  setImagePayload((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
              />
            </div>
            <div className="w-25-p">
              <Input
                placeholder="Image Caption"
                value={imagePayload.caption}
                onChange={(e) =>
                  setImagePayload((prev) => ({
                    ...prev,
                    caption: e.target.value,
                  }))
                }
              />
            </div>
            <div className="w-25-p">
              <Input
                placeholder="Image Alt Text"
                value={imagePayload.alt_text}
                onChange={(e) =>
                  setImagePayload((prev) => ({
                    ...prev,
                    alt_text: e.target.value,
                  }))
                }
              />
            </div>
            <div className="w-25-p">
              <Checkbox
                className="ms-3"
                checked={imagePayload.is_gallery_image}
                onChange={(value) =>
                  setImagePayload((prev) => ({
                    ...prev,
                    is_gallery_image: value.target.checked,
                  }))
                }
              >
                save into gallery ?
              </Checkbox>
            </div>
          </div>
          <div style={{ height: "40vh" }}>
            <Upload
              className="custom-upload"
              listType="picture-card"
              fileList={images}
              maxCount={1}
              showUploadList={false}
              accept="image/*"
              beforeUpload={() => false}
              onRemove={handleRemove}
              onChange={handleImages}
            >
              <div className="flex justify-center aic h-100 w-100">
                {images.length === 0 ? (
                  <h2 className="fs-2 text-center text-mute im-title">
                    + select <br />
                    or Drop files here
                  </h2>
                ) : (
                  <div className="image-preview-grid">
                    {images.map((image) => (
                      <img
                        key={image.uid}
                        className="rounded-5"
                        src={URL.createObjectURL(image.originFileObj)}
                        alt="upload"
                        style={{
                          width: "110px",
                          height: "110px",
                          margin: "5px",
                        }}
                      />
                    ))}
                  </div>
                )}
              </div>
            </Upload>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AddImage;
