import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";
import { fetchAll as fetchTimeSlots } from "../../../libs/redux/features/academic/timeslotsNewSlice";
import { fetchAll as fetchCourses } from "../../../libs/redux/features/academic/coursesNewSlice";
import { Button, Col, message, Modal, Row, Select, Spin } from "antd";
import { weekDayData } from "../../../components/utility";
import { FaPlus } from "react-icons/fa";
import { postData, putData } from "../../../libs/axios/axiosInstance";
import { debounceFetchWithSearch } from "../../../libs/axios/fetchUtility";

const { Option } = Select;

const RoutineUploadModal = ({ open, onCancel, dataToModify, onSuccess }) => {
  const dispatch = useDispatch();

  const [routine, setRoutine] = useState([
    {
      weekday: 0,
      class_info: null,
      course: null,
      teacher: null,
      time_slot: null,
    },
    {
      weekday: 0,
      class_info: null,
      course: null,
      teacher: null,
      time_slot: null,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleChange = (index, name, value) => {
    const updatedRoutines = routine.map((calendar, i) =>
      i === index ? { ...calendar, [name]: value } : calendar
    );
    setRoutine(updatedRoutines);
  };

  const handleRemove = (index) => {
    const updatedRoutines = routine.filter((_, i) => i !== index);
    setRoutine(updatedRoutines);
  };

  const handleAddRoutine = () => {
    if (routine.length < 30) {
      setRoutine([
        ...routine,
        {
          weekday: 0,
          class_info: null,
          course: null,
          teacher: null,
          time_slot: null,
        },
      ]);
    } else {
      message.warning("You can only add up to 30 routines.");
    }
  };

  const { data: classesData, isLoading: classLoading } = useSelector(
    (state) => state.classesNew
  );
  const { data: coursesData, isLoading: courseLoading } = useSelector(
    (state) => state.coursesNew
  );
  const { data: teachersData, isLoading: teacherLoading } = useSelector(
    (state) => state.teachersNew
  );
  const timeSlotsData = useSelector((state) => state.timeslotsNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
    if (timeSlotsData.length === 0) {
      dispatch(fetchTimeSlots());
    }
    if (coursesData.length === 0) {
      dispatch(fetchCourses());
    }
  }, [
    dispatch,
    classesData.length,
    teachersData.length,
    timeSlotsData.length,
    coursesData.length,
  ]);

  useEffect(() => {
    if (dataToModify.length > 0) {
      setRoutine(dataToModify);
    }
  }, [dataToModify]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(routine);
    setIsLoading(true);
    try {
      if (dataToModify.length > 0) {
        const updateRoutine = await putData(
          "academic/admin/class-routines/bulk-update/",
          routine
        );

        if (updateRoutine.status === 200) {
          message.success(
            `${routine.length} items has been updated successfully`
          );
          if (onSuccess) {
            onSuccess();
          }
        }
        setIsLoading(false);
      } else {
        const postRoutine = await postData(
          "academic/admin/class-routines/bulk-create/",
          routine
        );
        if (postRoutine.status === 201) {
          message.success(
            `${routine.length} items has been created successfully`
          );
          setRoutine([
            {
              weekday: 0,
              class_info: null,
              course: null,
              teacher: null,
              time_slot: null,
            },
            {
              weekday: 0,
              class_info: null,
              course: null,
              teacher: null,
              time_slot: null,
            },
          ]);
          if (onSuccess) {
            onSuccess();
          }
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log("error", error.response);

      if (error.response && error.response.data) {
        const errorData = error.response.data;

        if (Array.isArray(errorData)) {
          errorData.forEach((errItem) => {
            if (
              errItem.non_field_errors &&
              errItem.non_field_errors.length > 0
            ) {
              message.error(errItem.non_field_errors[0]);
            }
          });
        }
        setErrors(errorData);
      }

      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      centered
      title={
        <h2 className="fs-2 mb-3 ms-3 text-dark">Class Routine Bulk Upload</h2>
      }
      width={1000}
      onOk={handleSubmit}
    >
      <div className="page-content-section">
        <form action="">
          <Spin spinning={isLoading}>
            <Row className="responsive-row">
              {routine.map((routineItem, index) => (
                <Col span={24} md={12} key={index} className="mb-3">
                  <div className="bg-white border rounded-10">
                    <div
                      className="p-4 border-b flex-justify-between"
                      style={{ paddingBottom: 5 }}
                    >
                      <h2>Routine {index + 1}</h2>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemove(index)}
                      >
                        Remove
                      </p>
                    </div>
                    <div className="p-4" style={{ paddingTop: 10 }}>
                      <Row>
                        <Col span={24} md={12} className="mb-3">
                          <div className="">
                            <div className="mb-2 flex aic">
                              <p className="ps-2 fw-6">* Weekday</p>
                              {errors[index] && errors[index].weekday && (
                                <p className="text-danger">
                                  ({errors[index].weekday})
                                </p>
                              )}
                            </div>
                            <Select
                              className="w-100"
                              value={routineItem.weekday}
                              onChange={(value) =>
                                handleChange(index, "weekday", value)
                              }
                            >
                              {weekDayData.map((item) => (
                                <Option value={item.id} key={item.id}>
                                  {item.title}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Col>
                        <Col span={24} md={12} className="mb-3">
                          <div className="">
                            <div className="mb-2 flex aic">
                              <p className="ps-2 fw-6">* Class</p>
                              {errors[index] && errors[index].class_info && (
                                <p className="text-danger">
                                  ({errors[index].class_info})
                                </p>
                              )}
                            </div>
                            <Select
                              showSearch
                              placeholder="Select Class"
                              loading={classLoading}
                              allowClear
                              onSearch={(searchValue) =>
                                debounceFetchWithSearch(
                                  searchValue,
                                  dispatch,
                                  fetchClasses
                                )
                              }
                              filterOption={false}
                              className="w-100"
                              value={routineItem.class_info}
                              onChange={(value) =>
                                handleChange(index, "class_info", value)
                              }
                            >
                              {classesData &&
                                classesData.map((item) => (
                                  <Option value={item.id} key={item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </Col>
                        <Col span={24} md={12} className="mb-3">
                          <div className="">
                            <div className="mb-2 flex aic">
                              <p className="ps-2 fw-6">* Course</p>
                              {errors[index] && errors[index].course && (
                                <p className="text-danger">
                                  ({errors[index].course})
                                </p>
                              )}
                            </div>
                            <Select
                              showSearch
                              placeholder="Select Course"
                              loading={courseLoading}
                              allowClear
                              onSearch={(searchValue) =>
                                debounceFetchWithSearch(
                                  searchValue,
                                  dispatch,
                                  fetchCourses
                                )
                              }
                              filterOption={false}
                              className="w-100"
                              value={routineItem.course}
                              onChange={(value) =>
                                handleChange(index, "course", value)
                              }
                            >
                              {coursesData &&
                                coursesData.map((item) => (
                                  <Option value={item.id} key={item.id}>
                                    {item.name}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </Col>
                        <Col span={24} md={12} className="mb-3">
                          <div className="">
                            <div className="mb-2 flex aic">
                              <p className="ps-2 fw-6">* Teacher</p>
                              {errors[index] && errors[index].teacher && (
                                <p className="text-danger">
                                  ({errors[index].teacher})
                                </p>
                              )}
                            </div>
                            <Select
                              showSearch
                              placeholder="Select Teacher"
                              loading={teacherLoading}
                              allowClear
                              onSearch={(searchValue) =>
                                debounceFetchWithSearch(
                                  searchValue,
                                  dispatch,
                                  fetchTeachers
                                )
                              }
                              filterOption={false}
                              className="w-100"
                              value={routineItem.teacher}
                              onChange={(value) =>
                                handleChange(index, "teacher", value)
                              }
                            >
                              {teachersData &&
                                teachersData.map((item) => (
                                  <Option value={item.id} key={item.id}>
                                    {item.user.first_name} {item.user.last_name}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </Col>
                        <Col span={24} className="mb-3">
                          <div className="">
                            <div className="mb-2 flex aic">
                              <p className="ps-2 fw-6">* Time Slot</p>
                              {errors[index] && errors[index].time_slot && (
                                <p className="text-danger">
                                  ({errors[index].time_slot})
                                </p>
                              )}
                            </div>
                            <Select
                              showSearch
                              placeholder="Select Timeslot"
                              optionFilterProp="children"
                              allowClear
                              className="w-100"
                              value={routineItem.time_slot}
                              onChange={(value) =>
                                handleChange(index, "time_slot", value)
                              }
                            >
                              {timeSlotsData &&
                                timeSlotsData.map((item) => (
                                  <Option value={item.id} key={item.id}>
                                    {item.start_time} - {item.end_time}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <div className="flex w-100 justify-center">
              <Button
                type="primary"
                shape="circle"
                icon={<FaPlus />}
                className="mt-5"
                onClick={handleAddRoutine}
              />
            </div>
          </Spin>
        </form>
      </div>
    </Modal>
  );
};

export default RoutineUploadModal;
