import {
  Breadcrumb,
  Button,
  Switch,
  Table,
  Input,
  Select,
  Pagination,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  handleDelete,
  handleStatusChange,
  downloadPDF,
  downloadXLS,
} from "../../../components/utility";
import usePageTitle from "../../../components/PageTitle";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import { debounceFetchWithSearch } from "../../../libs/axios/fetchUtility";

const { Search } = Input;
const { Option } = Select;

const Classes = () => {
  usePageTitle("Classes");
  const dispatch = useDispatch();

  const data = useSelector((state) => state.classesNew.data);
  const isLoading = useSelector((state) => state.classesNew.isLoading);
  const totalItems = useSelector((state) => state.classesNew.totalItems);

  const { data: teachersData, isLoading: teacherLoading } = useSelector(
    (state) => state.teachersNew
  );

  useEffect(() => {
    dispatch(fetchTeachers());
  }, [dispatch]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    search: "",
    is_active: "all",
    has_sections: "all",
    class_teacher: null,
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.is_active !== "all") {
      params.is_active = filters.is_active;
    }
    if (filters.has_sections !== "all") {
      params.has_sections = filters.has_sections;
    }
    if (filters.class_teacher) {
      params.class_teacher = filters.class_teacher;
    }

    dispatch(fetchClasses(params));
  }, [dispatch, filters]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Class Name",
      dataIndex: "name",
      width: 120,
    },
    {
      title: "Level",
      dataIndex: "level",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `academic/admin/school-classes/${record.id}/`,
              fetchClasses,
              filters
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/classes/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.name}`,
                dispatch,
                `academic/admin/school-classes/${record.id}/`,
                fetchClasses,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Name: item.name || "N/A",
    Level: item.level || "0",
    Description: item.description || "N/A",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = ["Serial", "Name", "Level", "Description", "Status"];
    downloadPDF(data, columns, formatTimeSlotsData, "Classes Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Classes Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Classes</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Academic" },
              { title: "School Classes" },
            ]}
          />
        </div>
        <div>
          <Link to="/academic/classes/add-class">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by Class Name"
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
              onClear={() => handleFilterChange("search", "")}
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_active", value)}
            >
              <Option value="all">All Status</Option>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("has_sections", value)}
            >
              <Option value="all">All Classes</Option>
              <Option value={true}>With Sections</Option>
              <Option value={false}>With No Sections</Option>
            </Select>
            <Select
              showSearch
              loading={teacherLoading}
              className="w-200"
              placeholder="Select Teacher"
              value={filters.class_teacher}
              onChange={(value) => handleFilterChange("class_teacher", value)}
              onSearch={(searchValue) =>
                debounceFetchWithSearch(searchValue, dispatch, fetchTeachers)
              }
              filterOption={false}
              allowClear
            >
              {teachersData &&
                teachersData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.user.first_name} {item.user.last_name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Classes;
