import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchAll } from "../../../libs/redux/features/student-info/studentsSlice";
import usePageTitle from "../../../components/PageTitle";
import { Button, Col, message, Row, Select, Spin, Tag } from "antd";
import PageCard from "../../../components/PageCard";
import BreadBtn from "../../../components/BreadBtn";
import { fetchAll as fetchHomeworks } from "../../../libs/redux/features/academic/homeworksNewSlice";
import {
  fetchSingle as fetchSingleHomeworkSubmission,
  postData as postHomeworkSubmission,
  updateData as putHomeworkSubmission,
} from "../../../libs/redux/features/academic/homeworkSubmissionsSlice";
import { getDocIcon } from "../../../components/utility";
import DocumentPopup from "../../../components/DocumentPopup";
import { debounceFetchWithSearch } from "../../../libs/axios/fetchUtility";

const { Option } = Select;

const HomeworkSubmissionForm = () => {
  const breadcrumbitems = [
    { title: "Academic" },
    {
      title: (
        <Link to="/academic/homework-submissions">Homework Submissions</Link>
      ),
    },
    { title: "Add Homework Submission" },
  ];

  const { id } = useParams();
  const dispatch = useDispatch();

  const { singleData, isLoading, error } = useSelector(
    (state) => state.homeworkSubmissions
  );

  const { data: homeworksData, isLoading: homeworkLoading } = useSelector(
    (state) => state.homeworksNew
  );
  const { data: studentsData, isLoading: studentLoading } = useSelector(
    (state) => state.students
  );

  useEffect(() => {
    if (homeworksData.length === 0) {
      dispatch(fetchHomeworks());
    }
    if (studentsData.length === 0) {
      dispatch(fetchAll());
    }
  }, [dispatch, homeworksData.length, studentsData.length]);

  const [assignmentSubmission, setAssignmentSubmission] = useState({
    status: "Late",
    student: null,
    homework: null,
    attachments: [],
  });

  const [docModal, setDocModal] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleHomeworkSubmission(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setAssignmentSubmission({
        status: singleData.status,
        feedback: singleData.feedback,
        student: singleData.student,
        homework: singleData.homework,
        attachments: singleData.attachments_data,
      });
    }
  }, [id, singleData]);

  usePageTitle(id ? "Homework Submission Detail" : "Homework Submissions");

  const handleDocs = (data) => {
    setAssignmentSubmission((prev) => ({
      ...prev,
      attachments: [...prev.attachments, ...data],
    }));
    setDocModal(false);
  };

  const handleClose = (removedItem) => {
    setAssignmentSubmission((prev) => ({
      ...prev,
      attachments: prev.attachments.filter(
        (item) => item.id !== removedItem.id
      ),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...assignmentSubmission,
      attachments:
        assignmentSubmission.attachments.length > 0
          ? assignmentSubmission.attachments.map((item) => item.id)
          : [],
    };
    if (id) {
      dispatch(putHomeworkSubmission({ id, payload }));
    } else {
      dispatch(
        postHomeworkSubmission({
          payload: payload,
          onSuccess: () => {
            message.success("New homework submission created successfully!");
            setAssignmentSubmission({
              status: "Late",
              student: null,
              homework: null,
              attachments: [],
            });
          },
        })
      );
    }
  };
  return (
    <div>
      <BreadBtn headerTitle="Add Homework Submission" items={breadcrumbitems} />
      <PageCard
        title="Homework submission data"
        content={
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Student</p>
                      {error && error.student ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.student})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      showSearch
                      loading={studentLoading}
                      className="w-100"
                      size="large"
                      placeholder="Select Student"
                      onSearch={(searchValue) =>
                        debounceFetchWithSearch(searchValue, dispatch, fetchAll)
                      }
                      filterOption={false}
                      value={assignmentSubmission.student}
                      onChange={(value) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          student: value,
                        }))
                      }
                      allowClear
                      onClear={() =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          student: null,
                        }))
                      }
                      status={error && error.student ? "error" : ""}
                    >
                      {studentsData && studentsData.length > 0
                        ? studentsData.map((student) => (
                            <Option value={student.id} key={student.id}>
                              {student.user.first_name} {student.user.last_name}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Homework</p>
                      {error && error.homework ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.homework})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      loading={homeworkLoading}
                      showSearch
                      className="w-100"
                      size="large"
                      placeholder="Select Homework"
                      value={assignmentSubmission.homework}
                      onChange={(value) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          homework: value,
                        }))
                      }
                      onSearch={(searchValue) =>
                        debounceFetchWithSearch(
                          searchValue,
                          dispatch,
                          fetchHomeworks
                        )
                      }
                      filterOption={false}
                      allowClear
                      onClear={() =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          homework: null,
                        }))
                      }
                      status={error && error.homework ? "error" : ""}
                    >
                      {homeworksData && homeworksData.length > 0
                        ? homeworksData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.title}
                            </Option>
                          ))
                        : ""}
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <div className="mb-2 flex aic">
                      <p className="ps-2 fw-6">* Status</p>
                      {error && error.status ? (
                        <p className="text-sm text-danger ms-3">
                          ({error.status})
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <Select
                      className="w-100"
                      size="large"
                      placeholder="Select Status"
                      value={assignmentSubmission.status}
                      onChange={(value) =>
                        setAssignmentSubmission((prev) => ({
                          ...prev,
                          status: value,
                        }))
                      }
                      status={error && error.status ? "error" : ""}
                    >
                      <Option value={"Submitted"}>Submitted</Option>
                      <Option value={"Late"}>Late</Option>
                    </Select>
                  </div>
                </Col>
                <Col span={24}>
                  <p className="mb-2">* Attachments</p>
                  <div className="flex aic">
                    <Button
                      onClick={() => setDocModal(true)}
                      className={`flex justify-center aic rounded-10 ${
                        error && error.attachments
                          ? "border-dashed-double"
                          : "border-dashed"
                      }`}
                      style={{ height: 100, width: 100 }}
                      danger={error && error.attachments ? true : false}
                    >
                      + Upload
                    </Button>
                    <div className="ms-3">
                      {assignmentSubmission.attachments &&
                      assignmentSubmission.attachments.length > 0 ? (
                        assignmentSubmission.attachments.map((item) => {
                          return (
                            <Tag
                              key={item.id}
                              closable
                              onClose={() => handleClose(item)}
                              icon={getDocIcon(item.doc_type)}
                              className="m-1 p-2 flex aic"
                              color="success"
                            >
                              {item.title}
                            </Tag>
                          );
                        })
                      ) : error && error.attachments ? (
                        <p className="text-danger">{error.attachments}</p>
                      ) : (
                        "No file selected"
                      )}
                    </div>
                  </div>
                </Col>

                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {id ? "Update" : "Submit"}
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        }
      />
      <DocumentPopup
        open={docModal}
        onCancel={() => setDocModal(false)}
        onUploadSuccess={handleDocs}
        docDescription={`homework submission of ${assignmentSubmission.homework}, for ${assignmentSubmission.student} `}
      />
    </div>
  );
};

export default HomeworkSubmissionForm;
