import { createSliceFactory } from "../feature-components/sliceFactory";

const { reducer, fetchAll, fetchSingle, postData, updateData, error } =
  createSliceFactory({
    name: "homeworkFeedback",
    url: "academic/admin/homework-feedback/",
    fetchParams: (params) => {
      return params;
    },
  });

export { fetchAll, fetchSingle, postData, updateData, error };
export default reducer;
