import {
  Breadcrumb,
  Button,
  // Checkbox,
  Col,
  DatePicker,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchCourses } from "../../../libs/redux/features/academic/coursesNewSlice";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { fetchAll } from "../../../libs/redux/features/exams-features/examTypeSlice";
import {
  fetchAll as fetchAllExams,
  postData,
  updateData,
} from "../../../libs/redux/features/exams-features/examsSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import {
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import DataConversion from "../../../components/DataConversion";
import dayjs from "dayjs";
import ExamBulkUpload from "./ExamBulkUpload";
import { Link } from "react-router-dom";

const { Search } = Input;
const { Option } = Select;

const Exams = () => {
  usePageTitle("Exams");

  const breadcrumbitems = [
    {
      title: (
        <Link to="/">
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Exams Data",
    },
    { title: "Exams" },
  ];

  const [addModal, setAddModal] = useState(false);

  const dispatch = useDispatch();
  const exams = useSelector((state) => state.exams.data);
  const totalItems = useSelector((state) => state.exams.totalItems);
  const isLoading = useSelector((state) => state.exams.isLoading);
  const error = useSelector((state) => state.exams.error);

  const classesData = useSelector((state) => state.classesNew.data);
  const coursesData = useSelector((state) => state.coursesNew.data);
  const sectionsData = useSelector((state) => state.sectionsNew.data);
  const examTypesData = useSelector((state) => state.examTypes.data);

  const [modalData, setModalData] = useState({
    name: "",
    date: "",
    duration: "",
    total_marks: 100,
    exam_type: null,
    class_level: null,
    section: null,
    course: null,
  });

  useEffect(() => {
    dispatch(fetchClasses());

    dispatch(fetchAll());
  }, [dispatch]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    search: "",
    class_level: "all",
    course: "all",
    section: "all",
    exam_type: "all",
    date_after: "",
    date_before: "",
  });

  useEffect(() => {
    if (filters.class_level && filters.class_level !== "all") {
      dispatch(fetchCourses({ class_level: filters.class_level }));
      dispatch(fetchSections({ class_level: filters.class_level }));
    } else if (modalData.class_level && modalData.class_level !== null) {
      dispatch(fetchCourses({ class_level: modalData.class_level }));
      dispatch(fetchSections({ class_level: modalData.class_level }));
    } else {
      dispatch(fetchCourses());
      dispatch(fetchSections());
    }
  }, [dispatch, filters.class_level, modalData.class_level]);

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.class_level !== "all") {
      params.class_level = filters.class_level;
    }
    if (filters.course !== "all") {
      params.course = filters.course;
    }
    if (filters.section !== "all") {
      params.section = filters.section;
    }
    if (filters.exam_type !== "all") {
      params.exam_type = filters.exam_type;
    }
    if (filters.date_after !== "") {
      params.date_after = filters.date_after;
    }
    if (filters.date_before !== "") {
      params.date_before = filters.date_before;
    }

    dispatch(fetchAllExams(params));
  }, [dispatch, filters]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const formatTimeSlotsData = (item, index) => {
    const classname =
      classesData &&
      classesData.find((classes) => classes.id === item.class_level)?.name;
    const sectionName =
      sectionsData &&
      sectionsData.find((sections) => sections.id === item.section)?.name;
    const coursename =
      coursesData &&
      coursesData.find((courses) => courses.id === item.course)?.name;
    const examtypename =
      examTypesData &&
      examTypesData.find((types) => types.id === item.exam_type)?.name;
    return {
      Serial: index + 1,
      Name: item.name || "N/A",
      Date: item.date || "N/A",
      Duration: item.duration || "N/A",
      "Total Marks": item.total_marks || "N/A",
      "Exam Type": examtypename || "N/A",
      Class: classname || "N/A",
      Section: sectionName || "N/A",
      Course: coursename || "N/A",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Name",
      "Date",
      "Duration",
      "Total Marks",
      "Exam Type",
      "Class",
      "Section",
      "Course",
    ];
    downloadPDF(exams, columns, formatTimeSlotsData, "Exams Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(exams, formatTimeSlotsData, "Exams Data");
  };

  const [id, setId] = useState(null);

  const fetchSingleItem = (data) => {
    setModalData({
      name: data.name,
      date: data.date,
      duration: data.duration,
      total_marks: data.total_marks,
      exam_type: data.exam_type,
      class_level: data.class_level,
      section: data.section,
      course: data.course,
    });
    setId(data.id);
    setAddModal(true);
  };

  const isSection =
    modalData.class_level &&
    classesData.some(
      (item) => item.id === modalData.class_level && item.has_sections
    );

  // const [selectedRows, setSelectedRows] = useState([]);

  // console.log(selectedRows);

  const columns = [
    // {
    //   title: (
    //     <Checkbox
    //       onChange={(e) => {
    //         const checked = e.target.checked;
    //         if (checked) {
    //           setSelectedRows(exams);
    //         } else {
    //           setSelectedRows([]);
    //         }
    //       }}
    //       indeterminate={
    //         selectedRows.length > 0 && selectedRows.length < exams.length
    //       }
    //       checked={selectedRows.length === exams.length}
    //     />
    //   ),
    //   dataIndex: "checkbox",
    //   width: 50,
    //   fixed: "left",
    //   render: (_, record) => (
    //     <Checkbox
    //       checked={selectedRows.some((item) => item.id === record.id)}
    //       onChange={(e) => handleCheckboxChange(record, e.target.checked)}
    //     />
    //   ),
    // },
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Duration",
      dataIndex: "duration",
    },
    {
      title: "Total Marks",
      dataIndex: "total_marks",
    },
    {
      title: "Exam Type",
      dataIndex: "exam_type",
      render: (examtype) =>
        examTypesData &&
        examTypesData.find((item) => item.id === examtype)?.name,
    },
    {
      title: "Class",
      dataIndex: "class_level",
      render: (classLevel) =>
        classesData && classesData.find((item) => item.id === classLevel)?.name,
    },
    {
      title: "Section",
      dataIndex: "section",
      render: (section) =>
        sectionsData && sectionsData.find((item) => item.id === section)?.name,
    },
    {
      title: "Course",
      dataIndex: "course",
      render: (course) =>
        coursesData && coursesData.find((item) => item.id === course)?.name,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Button className="me-4 p-2" onClick={() => fetchSingleItem(record)}>
            <EyeOutlined />
          </Button>
          <div
            onClick={() =>
              handleDelete(
                `${record.name}`,
                dispatch,
                `exam/admin/exams/${record.id}/`,
                fetchAllExams,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  // const handleCheckboxChange = (record, checked) => {
  //   if (checked) {
  //     setSelectedRows((prevSelected) => [...prevSelected, record]);
  //   } else {
  //     setSelectedRows((prevSelected) =>
  //       prevSelected.filter((item) => item.id !== record.id)
  //     );
  //   }
  // };

  const handleModalCancel = () => {
    setAddModal(false);
    if (id) {
      setId(null);
    }
    setModalData((prev) => ({
      ...prev,
      name: "",
      date: "",
      duration: "",
      total_marks: 100,
    }));
  };

  const handleModalOk = () => {
    const payload = modalData;
    if (!isSection) {
      delete payload.section;
    }
    if (modalData)
      if (!id) {
        dispatch(
          postData({
            payload,
            onSuccess: () => {
              message.success("New exam added successfully!");
              setModalData((prev) => ({
                ...prev,
                name: "",
                date: "",
                duration: "",
                total_marks: 100,
              }));
              // setAddModal(false);
            },
          })
        );
      } else {
        dispatch(updateData({ id, payload }));
      }
  };

  const [bulkModal, setBulkModal] = useState(false);
  const handleCancelBulkModal = () => {
    setBulkModal(false);
  };

  const bulkModalClick = () => {
    setBulkModal(true);
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Exams</h1>
          <Breadcrumb className="fs-5" items={breadcrumbitems} />
        </div>
        <div>
          <Button
            icon={<PlusOutlined />}
            className="me-3"
            size="large"
            onClick={bulkModalClick}
          >
            Bulk create
          </Button>
          <Button
            onClick={() => setAddModal(true)}
            type="primary"
            icon={<PlusOutlined />}
            size="large"
          >
            Add New
          </Button>
        </div>
      </div>

      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search..."
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("class_level", value)}
            >
              <Option value="all">All Classes</Option>
              {classesData && classesData.length > 0
                ? classesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("course", value)}
            >
              <Option value="all">All Courses</Option>
              {coursesData && coursesData.length > 0
                ? coursesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("section", value)}
            >
              <Option value="all">All Sections</Option>
              {sectionsData && sectionsData.length > 0
                ? sectionsData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("exam_type", value)}
            >
              <Option value="all">All Exam Types</Option>
              {examTypesData && examTypesData.length > 0
                ? examTypesData.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))
                : ""}
            </Select>
            <DatePicker
              placeholder="Date after"
              className="w-200"
              value={filters.date_after ? dayjs(filters.date_after) : null}
              onChange={(value) =>
                handleFilterChange(
                  "date_after",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
            <DatePicker
              placeholder="Date Before"
              className="w-200"
              value={filters.date_before ? dayjs(filters.date_before) : null}
              onChange={(value) =>
                handleFilterChange(
                  "date_before",
                  value ? dayjs(value).format("YYYY-MM-DD") : ""
                )
              }
            />
          </div>
          <DataConversion
            handleDownloadPDF={handleDownloadPDF}
            handleDownloadXLS={handleDownloadXLS}
          />
        </div>

        <Table
          columns={columns}
          dataSource={exams}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
      {/* add and edit data modal */}
      {addModal && (
        <Modal
          title={<h2 className="fs-2 mb-3">Exams Data</h2>}
          open={addModal}
          width={1000}
          footer={[
            <Button key="cancel" onClick={handleModalCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={isLoading}
              loading={isLoading}
            >
              {id ? "Update" : "Submit"}
            </Button>,
          ]}
          onCancel={handleModalCancel}
        >
          <Row className="responsive-row">
            <Col span={24} md={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Name</p>
                  {error && error.name ? (
                    <p className="text-sm text-danger ms-3">({error.name})</p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  placeholder="Name"
                  required
                  value={modalData.name}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  status={error && error.name ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Date</p>
                  {error && error.date ? (
                    <p className="text-sm text-danger ms-3">({error.date})</p>
                  ) : (
                    ""
                  )}
                </div>
                <DatePicker
                  className="w-100"
                  placeholder="Date"
                  required
                  value={modalData.date ? dayjs(modalData.date) : null}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      date: e ? dayjs(e).format("YYYY-MM-DD") : null,
                    }))
                  }
                  status={error && error.date ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">Duration</p>
                  {error && error.duration ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.duration})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  placeholder="format - 02:00:00"
                  required
                  value={modalData.duration}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      duration: e.target.value,
                    }))
                  }
                  status={error && error.duration ? "error" : ""}
                />
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Total Marks</p>
                  {error && error.total_marks ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.total_marks})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Input
                  className="w-100"
                  placeholder="Total Marks"
                  required
                  type="number"
                  value={modalData.total_marks}
                  onChange={(e) =>
                    setModalData((prev) => ({
                      ...prev,
                      total_marks: e.target.value,
                    }))
                  }
                  status={error && error.total_marks ? "error" : ""}
                />
              </div>
            </Col>
            {/* <Col span={24} md={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Academic Year</p>
                  {error && error.academic_year ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.academic_year})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  className="w-100"
                  value={modalData.academic_year}
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      academic_year: value,
                    }))
                  }
                  status={error && error.academic_year ? "error" : ""}
                >
                  {academicYearData && academicYearData.length > 0
                    ? academicYearData.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.year}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col> */}
            <Col span={24} md={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Exam Type</p>
                  {error && error.exam_type ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.exam_type})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  placeholder="Select Exam Type"
                  className="w-100"
                  value={modalData.exam_type}
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      exam_type: value,
                    }))
                  }
                  status={error && error.exam_type ? "error" : ""}
                >
                  {examTypesData && examTypesData.length > 0
                    ? examTypesData.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Class</p>
                  {error && error.class_level ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.class_level})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  className="w-100"
                  placeholder="Select Class"
                  value={modalData.class_level}
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      class_level: value,
                    }))
                  }
                  status={error && error.class_level ? "error" : ""}
                >
                  {classesData && classesData.length > 0
                    ? classesData.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Section</p>
                  {error && error.section ? (
                    <p className="text-sm text-danger ms-3">
                      ({error.section})
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  className="w-100"
                  placeholder="Select Section"
                  value={modalData.section}
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      section: value,
                    }))
                  }
                  status={error && error.section ? "error" : ""}
                >
                  {sectionsData && sectionsData.length > 0
                    ? sectionsData.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="mb-3">
                <div className="mb-2 flex aic">
                  <p className="ps-2 fw-6">* Course</p>
                  {error && error.course ? (
                    <p className="text-sm text-danger ms-3">({error.course})</p>
                  ) : (
                    ""
                  )}
                </div>
                <Select
                  className="w-100"
                  value={modalData.course}
                  placeholder="Select Course"
                  onChange={(value) =>
                    setModalData((prev) => ({
                      ...prev,
                      course: value,
                    }))
                  }
                  status={error && error.course ? "error" : ""}
                >
                  {coursesData && coursesData.length > 0
                    ? coursesData.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))
                    : ""}
                </Select>
              </div>
            </Col>
          </Row>
        </Modal>
      )}
      {/* bulk create modal */}
      {bulkModal && (
        <ExamBulkUpload
          open={bulkModal}
          onCancel={handleCancelBulkModal}
          onSuccess={() => dispatch(fetchAllExams(filters))}
        />
      )}
    </div>
  );
};

export default Exams;
