import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Image,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UploadButton from "../../../components/UploadButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingle as fetchSingleSlider,
  postData as postSlider,
  updateData as putSlider,
} from "../../../libs/redux/features/website/sliderSlice";
import ImagePopup from "../../../components/ImagePopup";
import usePageTitle from "../../../components/PageTitle";
import { HomeOutlined } from "@ant-design/icons";

const SliderForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, singleData } = useSelector((state) => state.sliders);

  const [sliders, setSliders] = useState({
    title: "",
    sub_title: "",
    is_active: true,
    image: {},
  });

  useEffect(() => {
    if (!isNaN(id)) {
      dispatch(fetchSingleSlider(id));
    }
  }, [id, dispatch]);

  usePageTitle(!isNaN(id) ? `${singleData.title}` : "Add Slider");

  useEffect(() => {
    if (!isNaN(id) && singleData && Object.keys(singleData).length > 0) {
      setSliders(singleData);
    }
  }, [id, singleData]);

  const [imageModal, setImageModal] = useState(false);
  const handleImageUploadSuccess = (newImage) => {
    setSliders((prev) => ({
      ...prev,
      image: newImage[0],
    }));
    setImageModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isNaN(id)) {
      const payload = { ...sliders, image: sliders.image.id };
      dispatch(putSlider({ id: id, payload: payload }));
    } else {
      const image = sliders.image.id;
      if (image) {
        const payload = { ...sliders, image: image };
        dispatch(
          postSlider({
            payload,
            onSuccess: () => {
              message.success("New slider item created successfully!");
              setSliders({
                title: "",
                sub_title: "",
                is_active: true,
                image: {},
              });
            },
          })
        );
      } else {
        message.error("No image detected!");
      }
    }
  };
  return (
    <div>
      <div className="breadcrumb p-4">
        <div>
          <h1 className="fs-2 text-dark">
            {!isNaN(id) ? `${singleData.title}` : "Add Slider"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              {
                title: "Website",
              },
              {
                title: "Components",
              },
              {
                title: <Link to="/website/components/sliders">Sliders</Link>,
              },
              {
                title: !isNaN(id) ? `${singleData.title}` : "Add Slider",
              },
            ]}
          />
        </div>
      </div>
      <div className="bg-white rounded-10 mb-5">
        <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
          <h2 className="fs-4">Page Information</h2>
        </div>
        <div className="p-4" style={{ paddingTop: 10 }}>
          <Spin spinning={false}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Title</p>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Title"
                      value={sliders.title}
                      onChange={(e) =>
                        setSliders((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">
                      * Sub Title{" "}
                      <span
                        className="text-end ps-2"
                        style={{ fontSize: "12px", color: "#888" }}
                      >
                        {sliders.sub_title.length}/100
                      </span>
                    </p>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Sub Title"
                      maxLength={100}
                      value={sliders.sub_title}
                      onChange={(e) =>
                        setSliders((prev) => ({
                          ...prev,
                          sub_title: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-5">
                    <p className="mb-2 ps-2 fw-6">* Image</p>
                    <div className="image-preview-grid">
                      {sliders.image &&
                      Object.keys(sliders.image).length > 0 ? (
                        <Image
                          height={100}
                          width={100}
                          className="object-cover rounded-5"
                          alt={sliders.image.alt_text}
                          src={sliders.image.image}
                        />
                      ) : (
                        ""
                      )}
                      <UploadButton
                        onClick={() => setImageModal(true)}
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-5">
                    <Checkbox
                      checked={sliders.is_active}
                      onChange={(e) =>
                        setSliders((prev) => ({
                          ...prev,
                          is_active: e.target.checked,
                        }))
                      }
                    >
                      Is Active ?
                    </Checkbox>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        </div>
      </div>
      {imageModal && (
        <ImagePopup
          open={imageModal}
          onCancel={() => setImageModal(false)}
          onUploadSuccess={handleImageUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={`${sliders.title} - image`}
        />
      )}
    </div>
  );
};

export default SliderForm;
