import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import {
  fetchSingle,
  updateData,
  postData,
} from "../../../libs/redux/features/academic/homeworkFeedbackSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchSubmissions } from "../../../libs/redux/features/academic/homeworkSubmissionsSlice";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Input, message, Row, Select, Spin } from "antd";
import BreadBtn from "../../../components/BreadBtn";
import { debounceFetchWithSearch } from "../../../libs/axios/fetchUtility";

const { Option } = Select;

const HomeworkFeedbackForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(fetchSingle(id));
    }
  }, [id, dispatch]);

  const {
    isLoading: singleDataLoading,
    singleData,
    error,
  } = useSelector((state) => state.homeworkFeedback);

  const { data: submissionData, isLoading: submissionLoading } = useSelector(
    (state) => state.homeworkSubmissions
  );
  const { data: teachersData, isLoading: teacherLoading } = useSelector(
    (state) => state.teachersNew
  );

  usePageTitle(id ? "Feedback Detail" : "Add Feedback");

  const [feedback, setFeedback] = useState({
    grade: "",
    feedback: "",
    status: "Pending",
    submission: null,
    teacher: null,
  });

  useEffect(() => {
    dispatch(fetchSubmissions());
    dispatch(fetchTeachers());
  }, [dispatch]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setFeedback({
        grade: singleData.grade,
        feedback: singleData.feedback,
        status: singleData.status,
        submission: singleData.submission,
        teacher: singleData.teacher,
      });
    }
  }, [id, singleData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = feedback;
    if (id) {
      dispatch(updateData({ id, payload }));
    } else {
      dispatch(
        postData({
          payload,
          onSuccess: () => {
            message.success("New feedback created successfully!");
            setFeedback({
              grade: "",
              feedback: "",
              status: "Pending",
              submission: null,
              teacher: null,
            });
          },
        })
      );
    }
  };

  const breadcrumbitems = [
    {
      title: "Academic",
    },
    {
      title: <Link to="/academic/homework-feedback">Homework Feedback</Link>,
    },
    {
      title:
        id && singleData.homework_title
          ? singleData.homework_title
          : "Add Feedback",
    },
  ];

  return (
    <div>
      <BreadBtn
        headerTitle={
          id && singleData.homework_title
            ? singleData.homework_title
            : "Add Feedback"
        }
        items={breadcrumbitems}
      />
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={singleDataLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Homework Feedback Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Submission</p>
                        {error && error.submission ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.submission})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        loading={submissionLoading}
                        status={error && error.submission ? "error" : ""}
                        className="w-100"
                        placeholder="Select submitted homework"
                        size="large"
                        value={feedback.submission}
                        onChange={(value) =>
                          setFeedback((prev) => ({
                            ...prev,
                            submission: value,
                          }))
                        }
                        onSearch={(searchValue) =>
                          debounceFetchWithSearch(
                            searchValue,
                            dispatch,
                            fetchSubmissions
                          )
                        }
                        filterOption={false}
                      >
                        {submissionData &&
                          submissionData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.homework_title}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Grade</p>
                        {error && error.grade ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.grade})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Grade"
                        required
                        value={feedback.grade}
                        onChange={(e) =>
                          setFeedback((prev) => ({
                            ...prev,
                            grade: e.target.value,
                          }))
                        }
                        status={error && error.grade ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Feedback</p>
                        {error && error.feedback ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.feedback})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Input
                        className="w-100"
                        size="large"
                        placeholder="Feedback"
                        required
                        value={feedback.feedback}
                        onChange={(e) =>
                          setFeedback((prev) => ({
                            ...prev,
                            feedback: e.target.value,
                          }))
                        }
                        status={error && error.feedback ? "error" : ""}
                      />
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Status</p>
                        {error && error.status ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.status})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        placeholder="status"
                        className="w-100"
                        size="large"
                        value={feedback.status}
                        onChange={(value) =>
                          setFeedback((prev) => ({
                            ...prev,
                            status: value,
                          }))
                        }
                      >
                        <Option value={"Pending"}>Pending</Option>
                        <Option value={"Graded"}>Graded</Option>
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Teacher</p>
                        {error && error.teacher ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.teacher})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        loading={teacherLoading}
                        status={error && error.teacher ? "error" : ""}
                        className="w-100"
                        placeholder="Select Teacher"
                        size="large"
                        value={feedback.teacher}
                        onChange={(value) =>
                          setFeedback((prev) => ({
                            ...prev,
                            teacher: value,
                          }))
                        }
                        onSearch={(searchValue) =>
                          debounceFetchWithSearch(
                            searchValue,
                            dispatch,
                            fetchTeachers
                          )
                        }
                        filterOption={false}
                      >
                        {teachersData &&
                          teachersData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.user.first_name} {item.user.last_name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={singleDataLoading}
                    >
                      {id ? "Update" : "Add Feedback"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default HomeworkFeedbackForm;
