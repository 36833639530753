import { Breadcrumb, Input, Pagination, Select, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import { DeleteOutlined, HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import { fetchAll as fetchSections } from "../../../libs/redux/features/academic/sectionsNewSlice";
import { debounceFetchWithSearch } from "../../../libs/axios/fetchUtility";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";

const { Search } = Input;
const { Option } = Select;

const Sections = () => {
  usePageTitle("Sections");
  const dispatch = useDispatch();

  const data = useSelector((state) => state.sectionsNew.data);
  const isLoading = useSelector((state) => state.sectionsNew.isLoading);
  const totalItems = useSelector((state) => state.sectionsNew.totalItems);

  const { data: classesData, isLoading: classLoading } = useSelector(
    (state) => state.classesNew
  );

  const { data: teachersData, isLoading: teacherLoading } = useSelector(
    (state) => state.teachersNew
  );

  useEffect(() => {
    dispatch(fetchTeachers());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchClasses());
  }, [dispatch]);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    class_level: null,
    search: "",
    is_active: "all",
    class_teacher: null,
  });

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.class_level) {
      params.class_level = filters.class_level;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.is_active !== "all") {
      params.is_active = filters.is_active;
    }
    if (filters.class_teacher) {
      params.class_teacher = filters.class_teacher;
    }
    dispatch(fetchSections(params));
  }, [filters, dispatch]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: 200,
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
    },
    {
      title: "Class Level",
      dataIndex: "class_level",
      render: (classId) =>
        (classesData &&
          classesData.find((item) => item.id === classId)?.name) ||
        "Unknown",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active) =>
        is_active ? (
          <Tag bordered={false} color="success">
            Active
          </Tag>
        ) : (
          <Tag bordered={false} color="warning">
            Inactive
          </Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <div
            onClick={() =>
              handleDelete(
                `${record.name}`,
                dispatch,
                `academic/admin/sections/${record.id}/`,
                fetchSections
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => {
    const classId = classesData.find((items) => items.id === item.class_level);

    const className = classId ? classId.name : "N/A";

    return {
      Serial: index + 1,
      Name: item.name || "N/A",
      Capacity: item.capacity || "N/A",
      Description: item.description || "N/A",
      Class: className,
      Status: item.is_active ? "Active" : "Inactive",
    };
  };

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Name",
      "Capacity",
      "Class",
      "Description",
      "Status",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Sections Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Sections Data");
  };

  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">Sections</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Academic" },
              { title: "Sections" },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by name"
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
              onClear={() => handleFilterChange("search", "")}
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_active", value)}
            >
              <Option value="all">All Status</Option>
              <Option value={true}>Active</Option>
              <Option value={false}>Inactive</Option>
            </Select>
            <Select
              showSearch
              loading={classLoading}
              placeholder="Select Class"
              className="w-200"
              value={filters.class_level}
              onChange={(value) => handleFilterChange("class_level", value)}
              onSearch={(searchValue) =>
                debounceFetchWithSearch(searchValue, dispatch, fetchClasses)
              }
              filterOption={false}
              allowClear
            >
              {classesData &&
                classesData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
            <Select
              showSearch
              loading={teacherLoading}
              placeholder="Select Class Teacher"
              className="w-200"
              value={filters.class_teacher}
              onChange={(value) => handleFilterChange("class_teacher", value)}
              onSearch={(searchValue) =>
                debounceFetchWithSearch(searchValue, dispatch, fetchTeachers)
              }
              filterOption={false}
              allowClear
            >
              {teachersData &&
                teachersData.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.user.first_name} {item.user.last_name}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default Sections;
