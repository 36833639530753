import {
  Breadcrumb,
  Button,
  DatePicker,
  Input,
  Pagination,
  Select,
  Switch,
  Table,
  Checkbox,
  Tag, // Import Checkbox
} from "antd";
import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
  handleStatusChange,
} from "../../../components/utility";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { fetchAll as fetchCalendars } from "../../../libs/redux/features/academic/calendarsNewSlice";
import dayjs from "dayjs";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";
import { GrMultiple } from "react-icons/gr";
import CalendarBulkUpload from "./CalendarBulkUpload";

const { Search } = Input;
const { Option } = Select;

const Calendar = () => {
  usePageTitle("Academic Calendars");

  const dispatch = useDispatch();

  const data = useSelector((state) => state.calendarsNew.data);
  const isLoading = useSelector((state) => state.calendarsNew.isLoading);
  const totalItems = useSelector((state) => state.calendarsNew.totalItems);

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    start: null,
    end: null,
    search: "",
    is_active: "all",
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    let params = {
      limit: filters.limit,
      offset: filters.offset,
    };
    if (filters.start !== null) {
      params.start = filters.start;
    }
    if (filters.end !== null) {
      params.end = filters.end;
    }
    if (filters.search !== "") {
      params.search = filters.search;
    }
    if (filters.is_active !== "all") {
      params.is_active = filters.is_active;
    }
    dispatch(fetchCalendars(params));
  }, [dispatch, filters]);

  useEffect(() => {
    if (selectAll) {
      setSelectedItems(data);
    } else {
      setSelectedItems([]);
    }
  }, [selectAll, data]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleCheckboxChange = (record) => {
    const isSelected = selectedItems.some((item) => item.id === record.id);
    if (isSelected) {
      setSelectedItems(selectedItems.filter((item) => item.id !== record.id));
    } else {
      setSelectedItems([...selectedItems, record]);
    }
  };

  const columns = [
    {
      dataIndex: "select",
      width: 50,
      render: (_, record) => (
        <Checkbox
          checked={selectedItems.some((item) => item.id === record.id)}
          onChange={() => handleCheckboxChange(record)}
        />
      ),
      title: (
        <Checkbox
          checked={selectAll}
          indeterminate={
            selectedItems.length > 0 && selectedItems.length < data.length
          }
          onChange={(e) => setSelectAll(e.target.checked)}
        />
      ),
    },
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      fixed: "left",
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Start",
      dataIndex: "start",
      render: (date) => dayjs(date).format("HH:MM | YYYY-MM-DD"),
    },
    {
      title: "End",
      dataIndex: "end",
      render: (date) => dayjs(date).format("HH:MM | YYYY-MM-DD"),
    },
    {
      title: "All Day ?",
      dataIndex: "all_day",
      render: (record) =>
        record ? <Tag color="magenta">Yes</Tag> : <Tag color="red">No</Tag>,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      render: (is_active, record) => (
        <Switch
          checked={is_active}
          onChange={(checked) =>
            handleStatusChange(
              checked,
              record,
              dispatch,
              `academic/admin/calendar/${record.id}/`,
              fetchCalendars,
              filters
            )
          }
          checkedChildren="Active"
          unCheckedChildren="Inactive"
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link to={`/academic/calendars/${record.id}`} className="me-4">
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.title}`,
                dispatch,
                `academic/admin/calendar/${record.id}/`,
                fetchCalendars,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Title: item.title || "N/A",
    "Start Time": item.start || "N/A",
    "End Time": item.end || "N/A",
    Description: item.description || "N/A",
    "All Day ?": item.all_day ? "Yes" : "No",
    Status: item.is_active ? "Active" : "Inactive",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "TItle",
      "Start Time",
      "End Time",
      "Description",
      "All Day ?",
      "Status",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Academic Calendar Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Academic Calendar Data");
  };

  const [bulkUploadModal, setBulkUploadModal] = useState(false);

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Academic Calendars</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Academic" },
              { title: "Calendars" },
            ]}
          />
        </div>
        <div className="flex aic g-3">
          <Button
            icon={<GrMultiple />}
            size="large"
            onClick={() => setBulkUploadModal(true)}
          >
            {selectedItems.length === 0 ? "Bulk Upload" : "Bulk Modify"}
          </Button>

          <Link to="/academic/calendars/add-calendar">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3 mb-3-r">
            <Search
              placeholder="Search by title"
              onSearch={(value) => handleFilterChange("search", value)}
              className="w-200"
              allowClear
            />
            <DatePicker
              className="w-200"
              placeholder="Start date"
              value={filters.start ? dayjs(filters.start) : null}
              onChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  start: date ? date.format("YYYY-MM-DD") : null,
                }))
              }
            />
            <DatePicker
              className="w-200"
              placeholder="End date"
              value={filters.end ? dayjs(filters.end) : null}
              onChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  end: date ? date.format("YYYY-MM-DD") : null,
                }))
              }
            />
            <Select
              className="w-200"
              defaultValue="all"
              onChange={(value) => handleFilterChange("is_active", value)}
            >
              <Option value="all">All Status</Option>
              <Option value="true">Active</Option>
              <Option value="false">Inactive</Option>
            </Select>
          </div>
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>

      {bulkUploadModal && (
        <CalendarBulkUpload
          open={bulkUploadModal}
          onCancel={() => setBulkUploadModal(false)}
          dataToModify={selectedItems}
        />
      )}
    </div>
  );
};

export default Calendar;
