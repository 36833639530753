import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Image,
  Input,
  message,
  Row,
  Spin,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import UploadButton from "../../../components/UploadButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingle as fetchSingleSpeciality,
  postData as postSpeciality,
  updateData as putSpeciality,
} from "../../../libs/redux/features/website/specialitySlice";
import ImagePopup from "../../../components/ImagePopup";
import usePageTitle from "../../../components/PageTitle";
import { HomeOutlined } from "@ant-design/icons";

const SpecialityForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, singleData: singleSpeciality } = useSelector(
    (state) => state.specialities
  );

  const [specialities, setSpecialities] = useState({
    title: "",
    description: "",
    is_active: true,
    icon: {},
  });

  useEffect(() => {
    if (!isNaN(id)) {
      dispatch(fetchSingleSpeciality(id));
    }
  }, [id, dispatch]);
  console.log();

  usePageTitle(!isNaN(id) ? `${singleSpeciality.title}` : "Add Speciality");

  useEffect(() => {
    if (
      !isNaN(id) &&
      singleSpeciality &&
      Object.keys(singleSpeciality).length > 0
    ) {
      setSpecialities(singleSpeciality);
    }
  }, [id, singleSpeciality, dispatch]);

  const [imageModal, setImageModal] = useState(false);
  const handleImageUploadSuccess = (newImage) => {
    setSpecialities((prev) => ({
      ...prev,
      icon: newImage[0],
    }));
    setImageModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isNaN(id)) {
      const payload = { ...specialities, icon: specialities.icon.id };
      dispatch(putSpeciality({ id: id, payload: payload }));
    } else {
      const image = specialities.icon.id;
      if (image) {
        const payload = { ...specialities, icon: image };
        dispatch(
          postSpeciality({
            payload,
            onSuccess: () => {
              message.success("New speciality item created successfully!");
              setSpecialities({
                title: "",
                description: "",
                is_active: true,
                icon: {},
              });
            },
          })
        );
      } else {
        message.error("No icon detected!");
      }
    }
  };

  return (
    <div>
      <div className="breadcrumb p-4">
        <div>
          <h1 className="fs-2 text-dark">
            {!isNaN(id) ? `${singleSpeciality.title}` : "Add Speciality"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              {
                title: "Website",
              },
              {
                title: "Components",
              },
              {
                title: (
                  <Link to="/website/components/school-specialities">
                    School Specialities
                  </Link>
                ),
              },
              {
                title: !isNaN(id)
                  ? `${singleSpeciality.title}`
                  : "Add Speciality",
              },
            ]}
          />
        </div>
      </div>
      <div className="bg-white rounded-10 mb-5">
        <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
          <h2 className="fs-4">Page Information</h2>
        </div>
        <div className="p-4" style={{ paddingTop: 10 }}>
          <Spin spinning={isLoading}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Title</p>
                    <Input
                      className="w-100"
                      size="large"
                      placeholder="Title"
                      value={specialities.title}
                      onChange={(e) =>
                        setSpecialities((prev) => ({
                          ...prev,
                          title: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-3">
                    <p className="mb-2 ps-2 fw-6">* Description</p>
                    <TextArea
                      className="w-100"
                      autoSize={{ minRows: 2, maxRows: 4 }}
                      placeholder="Description"
                      value={specialities.description}
                      onChange={(e) =>
                        setSpecialities((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      required
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-5">
                    <p className="mb-2 ps-2 fw-6">* Image</p>
                    <div className="image-preview-grid">
                      {specialities.icon &&
                      Object.keys(specialities.icon).length > 0 ? (
                        <Image
                          height={100}
                          width={100}
                          className="object-cover rounded-5"
                          alt={specialities.icon.alt_text}
                          src={specialities.icon.image}
                        />
                      ) : (
                        ""
                      )}
                      <UploadButton
                        onClick={() => setImageModal(true)}
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="mb-5">
                    <Checkbox
                      checked={specialities.is_active}
                      onChange={(e) =>
                        setSpecialities((prev) => ({
                          ...prev,
                          is_active: e.target.checked,
                        }))
                      }
                    >
                      Is Active ?
                    </Checkbox>
                  </div>
                </Col>
                <Col span={24}>
                  <Button
                    className="w-100 mt-3"
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </form>
          </Spin>
        </div>
      </div>
      {imageModal && (
        <ImagePopup
          imgLimit={1}
          existingImgs={[specialities.icon]}
          open={imageModal}
          onCancel={() => setImageModal(false)}
          onUploadSuccess={handleImageUploadSuccess}
          cropAspectRatio={16 / 9}
          imageTitle={`${specialities.title} - image`}
        />
      )}
    </div>
  );
};

export default SpecialityForm;
