import { Breadcrumb, Button, Col, message, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchSingle as fetchSingleClassRoutine,
  postData as postClassRoutine,
  updateData as putClassRoutine,
} from "../../../libs/redux/features/academic/routinesNewSlice";
import { fetchAll as fetchClasses } from "../../../libs/redux/features/academic/classesNewSlice";
import { fetchAll as fetchTeachers } from "../../../libs/redux/features/teacher-info/teachersNewSlice";
import { fetchAll as fetchTimeSlots } from "../../../libs/redux/features/academic/timeslotsNewSlice";
import { weekDayData } from "../../../components/utility";
import { fetchAll as fetchCourses } from "../../../libs/redux/features/academic/coursesNewSlice";
import usePageTitle from "../../../components/PageTitle";
import { HomeOutlined } from "@ant-design/icons";
import { debounceFetchWithSearch } from "../../../libs/axios/fetchUtility";

const { Option } = Select;

const ClassRoutineForm = () => {
  const { id } = useParams();

  usePageTitle(id ? "Routine Detail" : "Add Class Routine");
  const dispatch = useDispatch();

  const singleData = useSelector((state) => state.routinesNew.singleData);
  const isLoading = useSelector((state) => state.routinesNew.isLoading);
  const error = useSelector((state) => state.routinesNew.error);

  const { data: classesData, isLoading: classLoading } = useSelector(
    (state) => state.classesNew
  );
  const { data: coursesData, isLoading: courseLoading } = useSelector(
    (state) => state.coursesNew
  );
  const { data: teachersData, isLoading: teacherLoading } = useSelector(
    (state) => state.teachersNew
  );
  const timeSlotsData = useSelector((state) => state.timeslotsNew.data);

  useEffect(() => {
    if (classesData.length === 0) {
      dispatch(fetchClasses());
    }
    if (teachersData.length === 0) {
      dispatch(fetchTeachers());
    }
    if (timeSlotsData.length === 0) {
      dispatch(fetchTimeSlots());
    }
  }, [dispatch, classesData.length, teachersData.length, timeSlotsData.length]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSingleClassRoutine(id));
    }
  }, [id, dispatch]);

  const [routines, setRoutines] = useState({
    weekday: 0,
    class_info: null,
    teacher: null,
    time_slot: null,
    course: null,
  });

  useEffect(() => {
    if (routines.class_info) {
      dispatch(fetchCourses({ class_level: routines.class_info }));
    } else {
      dispatch(fetchCourses());
    }
  }, [dispatch, routines.class_info]);

  useEffect(() => {
    if (id && Object.keys(singleData).length > 0) {
      setRoutines({
        weekday: singleData.weekday,
        class_info: singleData.class_info,
        teacher: singleData.teacher,
        time_slot: singleData.time_slot,
        course: singleData.course,
      });
    }
  }, [id, singleData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = routines;
    if (id) {
      dispatch(putClassRoutine({ id, payload }));
    } else {
      dispatch(
        postClassRoutine({
          payload,
          onSuccess: () => {
            message.success("New routine added successfully!");
            setRoutines({
              weekday: 0,
              class_info: null,
              teacher: null,
              time_slot: null,
              course: null,
            });
          },
        })
      );
    }
  };
  return (
    <div>
      <div className="breadcrumb">
        <div>
          <h1 className="fs-2 text-dark">
            {id ? "Routine Detail" : "Add Class Routine"}
          </h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              {
                title: "Academic",
              },
              {
                title: (
                  <Link to="/academic/class-routines">Class Routines</Link>
                ),
              },
              {
                title: id ? "Routine Detail" : "Add Class Routine",
              },
            ]}
          />
        </div>
      </div>
      <div className="page-content-section">
        <form onSubmit={handleSubmit}>
          <Spin spinning={isLoading}>
            <div className="bg-white rounded-10">
              <div className="p-4 border-b" style={{ paddingBottom: 5 }}>
                <h2 className="fs-4">Class Routine Information</h2>
              </div>
              <div className="p-4" style={{ paddingTop: 10 }}>
                <Row>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Weeek Day</p>
                        {error && error.weekday ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.weekday})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        status={error && error.weekday ? "error" : ""}
                        className="w-100"
                        size="large"
                        placeholder="Select Day"
                        value={routines.weekday || 0}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            weekday: value,
                          }))
                        }
                      >
                        {weekDayData.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Class</p>
                        {error && error.class_info ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.class_info})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        placeholder="Select Class"
                        onSearch={(searchValue) =>
                          debounceFetchWithSearch(
                            searchValue,
                            dispatch,
                            fetchClasses
                          )
                        }
                        filterOption={false}
                        loading={classLoading}
                        allowClear
                        status={error && error.class_info ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={routines.class_info || null}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            class_info: value,
                          }))
                        }
                      >
                        {classesData &&
                          classesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Course</p>
                        {error && error.course ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.course})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        placeholder="Select Course"
                        loading={courseLoading}
                        allowClear
                        onSearch={(searchValue) =>
                          debounceFetchWithSearch(
                            searchValue,
                            dispatch,
                            fetchCourses
                          )
                        }
                        filterOption={false}
                        status={error && error.course ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={routines.course || null}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            course: value,
                          }))
                        }
                      >
                        {coursesData &&
                          coursesData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* Teacher</p>
                        {error && error.teacher ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.teacher})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        placeholder="Select Teacher"
                        loading={teacherLoading}
                        allowClear
                        onSearch={(searchValue) =>
                          debounceFetchWithSearch(
                            searchValue,
                            dispatch,
                            fetchTeachers
                          )
                        }
                        filterOption={false}
                        status={error && error.teacher ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={routines.teacher || null}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            teacher: value,
                          }))
                        }
                      >
                        {teachersData &&
                          teachersData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.user.first_name} {item.user.last_name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="mb-3">
                      <div className="mb-2 flex aic">
                        <p className="ps-2 fw-6">* TimeSlot</p>
                        {error && error.time_slot ? (
                          <p className="text-sm text-danger ms-3">
                            ({error.time_slot})
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Select
                        showSearch
                        placeholder="Select Timeslot"
                        optionFilterProp="children"
                        allowClear
                        status={error && error.time_slot ? "error" : ""}
                        className="w-100"
                        size="large"
                        value={routines.time_slot || null}
                        onChange={(value) =>
                          setRoutines((prev) => ({
                            ...prev,
                            time_slot: value,
                          }))
                        }
                      >
                        {timeSlotsData &&
                          timeSlotsData.map((item) => (
                            <Option value={item.id} key={item.id}>
                              {item.start_time} - {item.end_time}
                            </Option>
                          ))}
                      </Select>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Button
                      className="w-100 mt-3"
                      type="primary"
                      size="large"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      {id ? "Update" : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Spin>
        </form>
      </div>
    </div>
  );
};

export default ClassRoutineForm;
