import React, { useEffect, useState } from "react";
import usePageTitle from "../../../components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { fetchAll as fetchFeedbacks } from "../../../libs/redux/features/academic/assignmentFeedbackSlice";
import { Link } from "react-router-dom";
import {
  downloadPDF,
  downloadXLS,
  handleDelete,
} from "../../../components/utility";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Pagination, Table, Tag } from "antd";
import { FaFilePdf } from "react-icons/fa6";
import { PiFileXlsBold } from "react-icons/pi";

const AssignmentFeedback = () => {
  usePageTitle("Assignment Feedback");
  const dispatch = useDispatch();
  const { data, totalItems, isLoading } = useSelector(
    (state) => state.assignmentFeedback
  );

  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
  });

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    dispatch(fetchFeedbacks(filters));
  }, [dispatch, filters]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: 50,
      render: (text, record, index) => filters.offset + index + 1,
    },
    {
      title: "Student",
      dataIndex: "student_name",
    },
    {
      title: "Assignment",
      dataIndex: "assignment_title",
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
    },
    {
      title: "Grade",
      dataIndex: "grade",
    },
    {
      title: "Teacher",
      dataIndex: "teacher_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) =>
        status === "Graded" ? (
          <Tag color="green">Graded</Tag>
        ) : (
          <Tag color="Red">Pedning</Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 100,
      render: (_, record) => (
        <div className="flex aic">
          <Link
            to={`/academic/assignment-feedback/${record.id}`}
            className="me-4"
          >
            <EyeOutlined />
          </Link>
          <div
            onClick={() =>
              handleDelete(
                `${record.assignment_title}`,
                dispatch,
                `academic/admin/assignment-feedback/${record.id}/`,
                fetchFeedbacks,
                filters
              )
            }
          >
            <DeleteOutlined className="text-danger c-pointer" />
          </div>
        </div>
      ),
    },
  ];

  const formatTimeSlotsData = (item, index) => ({
    Serial: index + 1,
    Student: item.student_name || "N/A",
    Assingment: item.assignment_title || "N/A",
    Status: item.status === "Pending" ? "Pending" : "Graded",
    Grade: item.grade || "N/A",
    "Graded Date": item.graded_date || "N/A",
    Teacher: item.teacher || "N/A",
  });

  const handleDownloadPDF = () => {
    const columns = [
      "Serial",
      "Student",
      "Assingment",
      "Status",
      "Grade",
      "Graded Date",
      "Teacher",
    ];
    downloadPDF(data, columns, formatTimeSlotsData, "Assignment Feedback Data");
  };

  const handleDownloadXLS = () => {
    downloadXLS(data, formatTimeSlotsData, "Assignment Feedback Data");
  };

  return (
    <div>
      <div className="breadcrumb flex-justify-between">
        <div>
          <h1 className="fs-2 text-dark">Assignment Feedback</h1>
          <Breadcrumb
            className="fs-5"
            items={[
              {
                title: (
                  <Link to="/">
                    <HomeOutlined />
                  </Link>
                ),
              },
              { title: "Academic" },
              { title: "Assignment Feedback" },
            ]}
          />
        </div>
        <div>
          <Link to="/academic/assignment-feedback/add-feedback">
            <Button type="primary" icon={<PlusOutlined />} size="large">
              Add New
            </Button>
          </Link>
        </div>
      </div>
      <div className="page-content-section bg-white p-4 rounded-10 shadow-sm-2">
        <div className="mb-3 flex-justify-between">
          <div className="flex aic g-3">
            <div
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
              onClick={handleDownloadPDF}
            >
              <FaFilePdf className="export-icon tr-03 fs-5" />
            </div>
            <div
              onClick={handleDownloadXLS}
              className=" border rounded-5 flex aic justify-center c-pointer export-icon-wrap"
              style={{ padding: "7px 10px" }}
            >
              <PiFileXlsBold className="export-icon tr-03 fs-5" />
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          loading={isLoading}
          rowKey={(record) => record.id}
          pagination={false}
          bordered
          scroll={{ x: 991 }}
          footer={() => {
            const startItem = filters.offset + 1;
            const endItem = Math.min(
              filters.offset + filters.limit,
              totalItems
            );

            return (
              <div className="flex-justify-between">
                <Pagination
                  current={Math.floor(filters.offset / filters.limit) + 1}
                  pageSize={filters.limit}
                  total={totalItems}
                  onChange={(page, pageSize) => {
                    const newOffset = (page - 1) * pageSize;
                    handleFilterChange("offset", newOffset);
                    handleFilterChange("limit", pageSize);
                  }}
                  showSizeChanger
                  pageSizeOptions={["10", "20", "30"]}
                />
                <p>
                  Showing {startItem} to {endItem} of total {totalItems} items
                </p>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default AssignmentFeedback;
