import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Spin,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { postData, putData } from "../../../libs/axios/axiosInstance";

const DISPLAY_FORMAT = "DD/MM/YYYY HH:mm";
const SERVER_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

const CalendarBulkUpload = ({ open, onCancel, dataToModify = [] }) => {
  const [calendars, setCalendars] = useState([
    {
      title: "",
      description: "",
      start: "",
      end: "",
      all_day: false,
      is_active: true,
    },
    {
      title: "",
      description: "",
      start: "",
      end: "",
      all_day: false,
      is_active: true,
    },
  ]);

  useEffect(() => {
    if (dataToModify.length > 0) {
      const modifiedData = dataToModify.map((calendar) => ({
        ...calendar,
        start: calendar.start ? dayjs(calendar.start) : "",
        end: calendar.end ? dayjs(calendar.end) : "",
      }));
      setCalendars(modifiedData);
    }
  }, [dataToModify]);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleDateChange = (index, name, value) => {
    const updatedCalendars = calendars.map((calendar, i) =>
      i === index
        ? {
            ...calendar,
            [name]: value ? dayjs(value) : "",
          }
        : calendar
    );
    setCalendars(updatedCalendars);
  };

  const handleChange = (index, name, value) => {
    const updatedCalendars = calendars.map((calendar, i) =>
      i === index ? { ...calendar, [name]: value } : calendar
    );
    setCalendars(updatedCalendars);
  };

  const handleRemove = (index) => {
    const updatedCalendars = calendars.filter((_, i) => i !== index);
    setCalendars(updatedCalendars);
  };

  const handleAddCalendar = () => {
    if (calendars.length < 30) {
      setCalendars([
        ...calendars,
        {
          title: "",
          description: "",
          start: "",
          end: "",
          all_day: false,
          is_active: true,
        },
      ]);
    } else {
      message.warning("You can only add up to 30 calendars.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const modifiedData = calendars.map(
        ({ created_at, updated_at, ...item }) => ({
          ...item,
          start: item.start ? dayjs(item.start).format(SERVER_FORMAT) : null,
          end: item.end ? dayjs(item.end).format(SERVER_FORMAT) : null,
        })
      );

      console.log("payload", modifiedData);

      if (dataToModify.length === 0) {
        // Create new calendars
        const bulkPost = await postData(
          "academic/admin/calendar/bulk-create/",
          modifiedData
        );
        console.log(bulkPost);
        if (bulkPost.status === 201) {
          message.success(
            `${calendars.length} items have been created successfully`
          );
          setCalendars([
            {
              title: "",
              description: "",
              start: "",
              end: "",
              all_day: false,
              is_active: true,
            },
            {
              title: "",
              description: "",
              start: "",
              end: "",
              all_day: false,
              is_active: true,
            },
          ]);
        }
      } else {
        // Update existing calendars
        const bulkModify = await putData(
          "academic/admin/calendar/bulk-update/",
          modifiedData
        );
        console.log(bulkModify);
        if (bulkModify.status === 200) {
          message.success(
            `${calendars.length} items has been updated successfully`
          );
        }
      }

      setIsLoading(false);
      setErrors([]);
    } catch (error) {
      console.log(error.response);

      if (error.response && error.response.data) {
        const errorData = error.response.data;

        if (Array.isArray(errorData)) {
          errorData.forEach((errItem) => {
            if (
              errItem.non_field_errors &&
              errItem.non_field_errors.length > 0
            ) {
              message.error(errItem.non_field_errors[0]);
            }
          });
        }
        setErrors(errorData);
      }

      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={
        <h2 className="fs-2 mb-3 ms-3 text-dark">
          Academic Calendar Bulk Upload
        </h2>
      }
      centered
      open={open}
      onCancel={onCancel}
      width={1000}
      onOk={handleSubmit}
    >
      <div className="page-content-section">
        <form>
          <Spin spinning={isLoading}>
            <Row className="responsive-row">
              {calendars.map((calendar, index) => (
                <Col span={24} md={12} key={index} className="mb-3">
                  <div className="bg-white border rounded-10">
                    <div
                      className="p-4 border-b flex-justify-between"
                      style={{ paddingBottom: 5 }}
                    >
                      <h2 className="fs-4">Calendar {index + 1}</h2>
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemove(index)}
                      >
                        Remove
                      </p>
                    </div>
                    <div className="p-4" style={{ paddingTop: 10 }}>
                      <Row className="aic">
                        <Col span={24} md={12}>
                          <div className="mb-3">
                            <div className="mb-2 flex aic">
                              <p className="ps-2 fw-6">* Start Date</p>
                              {errors[index] && errors[index].start && (
                                <p className="text-danger">
                                  ({errors[index].start})
                                </p>
                              )}
                            </div>
                            <DatePicker
                              className="w-100 rounded-10"
                              showTime
                              name="start"
                              onChange={(date) =>
                                handleDateChange(index, "start", date)
                              }
                              format={DISPLAY_FORMAT}
                              value={
                                calendar.start ? dayjs(calendar.start) : null
                              }
                              placeholder="Start Date"
                            />
                          </div>
                        </Col>
                        <Col span={24} md={12}>
                          <div className="mb-3">
                            <div className="mb-2 flex aic">
                              <p className="ps-2 fw-6">* End Date</p>
                              {errors[index] && errors[index].end && (
                                <p className="text-danger">
                                  ({errors[index].end})
                                </p>
                              )}
                            </div>
                            <DatePicker
                              className="w-100 rounded-10"
                              showTime
                              name="end"
                              onChange={(date) =>
                                handleDateChange(index, "end", date)
                              }
                              format={DISPLAY_FORMAT}
                              value={calendar.end ? dayjs(calendar.end) : null}
                              placeholder="End Date"
                            />
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="mb-3">
                            <div className="mb-2 flex aic">
                              <p className="ps-2 fw-6">* Title</p>
                              {errors[index] && errors[index].title && (
                                <p className="text-danger">
                                  ({errors[index].title})
                                </p>
                              )}
                            </div>
                            <Input
                              className="w-100"
                              placeholder="Name"
                              required
                              value={calendar.title}
                              onChange={(e) =>
                                handleChange(index, "title", e.target.value)
                              }
                            />
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="mb-3">
                            <p className="mb-2 ps-2 fw-6">* Description</p>
                            <TextArea
                              className="w-100"
                              autoSize={{ minRows: 1, maxRows: 2 }}
                              placeholder="Description"
                              required
                              value={calendar.description}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        </Col>
                        <Col span={24} md={12}>
                          <div className="my-3">
                            <Checkbox
                              checked={calendar.all_day}
                              onChange={(e) =>
                                handleChange(index, "all_day", e.target.checked)
                              }
                            >
                              All Day ?
                            </Checkbox>
                          </div>
                        </Col>
                        <Col span={24} md={12}>
                          <div className="my-3">
                            <Checkbox
                              checked={calendar.is_active}
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "is_active",
                                  e.target.checked
                                )
                              }
                            >
                              Is Active ?
                            </Checkbox>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <div className="flex w-100 justify-center">
              <Button
                type="primary"
                shape="circle"
                icon={<FaPlus />}
                className="mt-5"
                onClick={handleAddCalendar}
              />
            </div>
          </Spin>
        </form>
      </div>
    </Modal>
  );
};

export default CalendarBulkUpload;
